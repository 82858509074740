import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteActivity,
	getActivityById,
	postActivity,
	updateActivity,
} from "../../../../api/activity";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Container, Row, Col } from 'react-bootstrap';

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyActivity() {
	return {
		fullName: {},
		imageURL: null,
		active: true,
		kcal1: '',
		fat1: '',
		carbohydrates1: '',
		kcal2: '',
		fat2: '',
		carbohydrates2: '',
		kcal3: '',
		fat3: '',
		carbohydrates3: '',
		kcal4: '',
		fat4: '',
		carbohydrates4: '',
		kcal5: '',
		fat5: '',
		carbohydrates5: '',
	};
}

export default function EditActivitiesPage() {
	const [activity, setActivity] = useState(getEmptyActivity());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const activityId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!activityId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [activity])

	useEffect(() => {
		if (!activityId) {
			disableLoadingData();
			return;
		}
		getActivityById(activityId)
			.then((res) => {
				if (res.status === 200) {
					setActivity(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get activity.",
				});
				history.push("/activities");
			});
	}, [activityId, disableLoadingData, history]);

	function saveActivity() {
		if (checkIsEmpty(activity.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!activityId) {
			postActivity(activity, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Activity successfully created.",
						});
						history.push("/activities");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save activity.",
					});
				});
		} else {
			updateActivity(activityId, activity, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/activities");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!activity[element]) activity[element] = {};
			let newText = activity[element];
			newText[lang] = event.target.value;
			setActivity({ ...activity, [element]: newText });
		} else
			setActivity({
				...activity,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(activity.fullName && activity.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={activity?._id?'Activity: ' + (activity.fullName?activity.fullName.es:''):'New activity'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/activities");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveActivity()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save activity
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/activities")
							}}
						/>
						{activityId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete activity
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this activity?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteActivity(activityId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Activity deleted successfully",
													});
													history.push("/activities");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete activity.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col sm={3}>
								<h4>Level 1</h4>
								<div>Enter the values ​​for the level</div>
							</Col>
							<Col sm={3}>
								<TextField
									id={`kcal1`}
									label="KCAL"
									value={activity.kcal1}
									onChange={handleChange('kcal1')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`fat1`}
									label="FAT"
									value={activity.fat1}
									onChange={handleChange('fat1')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`carbohydrates1`}
									label="CHO"
									value={activity.carbohydrates1}
									onChange={handleChange('carbohydrates1')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<h4>Level 2</h4>
								<div>Enter the values ​​for the level</div>
							</Col>
							<Col sm={3}>
								<TextField
									id={`kcal2`}
									label="KCAL"
									value={activity.kcal2}
									onChange={handleChange('kcal2')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`fat2`}
									label="FAT"
									value={activity.fat2}
									onChange={handleChange('fat2')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`carbohydrates2`}
									label="CHO"
									value={activity.carbohydrates2}
									onChange={handleChange('carbohydrates2')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<h4>Level 3</h4>
								<div>Enter the values ​​for the level</div>
							</Col>
							<Col sm={3}>
								<TextField
									id={`kcal3`}
									label="KCAL"
									value={activity.kcal3}
									onChange={handleChange('kcal3')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`fat3`}
									label="FAT"
									value={activity.fat3}
									onChange={handleChange('fat3')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`carbohydrates3`}
									label="CHO"
									value={activity.carbohydrates3}
									onChange={handleChange('carbohydrates3')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<h4>Level 4</h4>
								<div>Enter the values ​​for the level</div>
							</Col>
							<Col sm={3}>
								<TextField
									id={`kcal4`}
									label="KCAL"
									value={activity.kcal4}
									onChange={handleChange('kcal4')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`fat4`}
									label="FAT"
									value={activity.fat4}
									onChange={handleChange('fat4')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`carbohydrates4`}
									label="CHO"
									value={activity.carbohydrates4}
									onChange={handleChange('carbohydrates4')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
						</Row>
						<Row>
							<Col sm={3}>
								<h4>Level 5</h4>
								<div>Enter the values ​​for the level</div>
							</Col>
							<Col sm={3}>
								<TextField
									id={`kcal5`}
									label="KCAL"
									value={activity.kcal5}
									onChange={handleChange('kcal5')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`carbohydrates5`}
									label="CHO"
									value={activity.carbohydrates5}
									onChange={handleChange('carbohydrates5')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
							<Col sm={3}>
								<TextField
									id={`fat5`}
									label="FAT"
									value={activity.fat5}
									onChange={handleChange('fat5')}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									type='num'
									required
								/>
							</Col>
						</Row>
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="standard"
							>
								{selectedImage ||
								(activity.imageURL &&
									activity.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(activity.imageURL &&
								activity.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${activity.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: activity.imageURL &&
										  activity.imageURL !== ""
										? activity.imageURL?.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setActivity({
												...activity,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={activity.active}
									onChange={() =>
										setActivity({
											...activity,
											active: !activity.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
