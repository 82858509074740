import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	deleteVisitType,
	getVisitTypeById,
	postVisitType,
	updateVisitType,
} from "../../../../api/visitType";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyVisitType() {
	return {
		fullName: {},
		imageURL: null,
		active: true,
	};
}

export default function EditVisitTypesPage() {
	const [visitType, setVisitType] = useState(getEmptyVisitType());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);

	const visitTypeId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!visitTypeId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [visitType])

	useEffect(() => {
		if (!visitTypeId) {
			disableLoadingData();
			return;
		}
		getVisitTypeById(visitTypeId)
			.then((res) => {
				if (res.status === 200) {
					setVisitType(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get visit type.",
				});
				history.push("/visit-types");
			});
	}, [visitTypeId, disableLoadingData, history]);

	function saveVisitType() {
		if (checkIsEmpty(visitType.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!visitTypeId) {
			postVisitType(visitType, selectedImage)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Visit type successfully created.",
						});
						history.push("/visit-types");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save visit type.",
					});
				});
		} else {
			updateVisitType(visitTypeId, visitType, selectedImage)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/visit-types");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!visitType[element]) visitType[element] = {};
			let newText = visitType[element];
			newText[lang] = event.target.value;
			setVisitType({ ...visitType, [element]: newText });
		} else
			setVisitType({
				...visitType,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(visitType.fullName && visitType.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={visitType?._id?'Visit type: ' + (visitType.fullName?visitType.fullName.es:''):'New visit type'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/visit-types");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveVisitType()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save visit type
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/visit-types")
							}}
						/>
						{visitTypeId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete visit type
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this visit type?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteVisitType(visitTypeId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Visit type deleted successfully",
													});
													history.push("/visit-types");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete visit type.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<label htmlFor={"upload-image"} className="ml-0">
							<input
								style={{ display: "none" }}
								id={"upload-image"}
								name={"upload-image"}
								type="file"
								accept={"image/*"}
								onChange={(e) => {
									setSelectedImage(e.target.files[0]);
								}}
							/>
							<Button
								style={{ marginRight: "15px" }}
								color="secondary"
								component="span"
								variant="standard"
							>
								{selectedImage ||
								(visitType.imageURL &&
									visitType.imageURL !== "")
									? "Change image"
									: "Upload image"}
							</Button>
						</label>
						{(selectedImage ||
							(visitType.imageURL &&
								visitType.imageURL !== "")) && (
							<>
								<Tooltip title={"Preview image"}>
									<Button
										size="small"
										onClick={() =>
											setOpenPreviewDialog(true)
										}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Visibility />
									</Button>
								</Tooltip>
								<PreviewDialog
									title={"Preview image"}
									open={openPreviewDialog}
									setOpen={setOpenPreviewDialog}
									src={
										selectedImage
											? URL.createObjectURL(selectedImage)
											: `${SERVER_URL}/${visitType.imageURL}`
									}
								/>
								<span>
									{selectedImage
										? selectedImage?.name
										: visitType.imageURL &&
										  visitType.imageURL !== ""
										? visitType.imageURL?.split(
												/-(.*)/s
										  )[1]
										: ""}
								</span>
								<Tooltip title={"Delete image"}>
									<Button
										size="small"
										onClick={() => {
											setSelectedImage(null);
											setVisitType({
												...visitType,
												imageURL: null,
											});
										}}
										style={{
											...buttonsStyle,
											marginRight: "15px",
										}}
									>
										<Delete />
									</Button>
								</Tooltip>
							</>
						)}
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={visitType.active}
									onChange={() =>
										setVisitType({
											...visitType,
											active: !visitType.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
