import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import FullCalendarView from "../../../components/calendar/FullCalendarView";
import { getVisitTypes } from "../../../../api/visitType";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { getPatients } from "../../../../api/user";
import { deleteVisit, postVisit, updateVisit } from "../../../../api/visit";
import CalendarDialogs from "../../../components/dialogs/calendar/CalendarDialogs";
import { deleteTask, postTask, updateTask } from "../../../../api/task";

export default function CalendarPage() {
	const [date, setDate] = useState(null);
	const [openCalendarDialogs, setOpenCalendarDialogs] = useState(false);
	const [visitTypes, setVisitTypes] = useState([]);
	const [visits, setVisits] = useState([]);
	const [tasks, setTasks] = useState([]);

	const [users, setUsers] = useState([]);
	const [selectedVisit, setSelectedVisit] = useState(null);
	const [typeEvent, setTypeEvent] = useState(0);
	const [initialDate, setInitialDate] = useState(new Date().toISOString().split("T")[0]);

	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
	},[refresh])

	useEffect(() => {
        if (visitTypes?.length === 0)
			getVisitTypes()
				.then((res) => {
				if (res.status === 200) {
					setVisitTypes(res.data);
				}
				})
				.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get visit types.",
				});
				});
		
		if (users?.length === 0)
			getPatients()
				.then((res) => {
					if (res.status === 200) {
						setUsers(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get patients.",
					});
				});
	}, []);

	function saveVisitToBD(visit) {
		if (visit?._id)
			updateVisit(visit._id, visit)
				.then((res) => {
					alertSuccess({
						title: 'Saved!',
						customMessage: "Visit updated succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update visit"
					})
				})
		else {
			postVisit(visit)
				.then((res) => {
					alertSuccess({
						title: 'Saved!',
						customMessage: "Visit updated succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save visit"
					})
				})
		}
	}

	async function deleteVisitFromBD(visit) {
        await deleteVisit(visit?._id)
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not erase visit"
                });
                return false;
            })   
        
        alertSuccess({
            title: "Deleted!",
            customMessage: "Visit deleted successfully"
        })
        return true;
    }

	async function deleteTaskFromBD(task) {
        await deleteTask(task?._id)
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not erase task"
                });
                return false;
            })   
        
        alertSuccess({
            title: "Deleted!",
            customMessage: "Task deleted successfully"
        })
        return true;
    }

	function saveTaskToBD(task) {
		if (task?._id) {
			updateTask(task?._id, task)
				.then((res) => {
					alertSuccess({
						title: "Saved!",
						customMessage: "Task updated succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update task"
					})
				})
		}
		else {
			postTask(task)
				.then((res) => {
					alertSuccess({
						title: "Saved!",
						customMessage: "Task saved succesfully"
					})
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save task"
					})
				})
		}
	}

	function deleteVis(visit) {
		let newVisits = [...visits] || [];
		const index = newVisits?.findIndex((x) => x?._id === visit?._id);
		if (index !== -1) newVisits.splice(index, 1);

		setVisits(newVisits);
		deleteVisitFromBD(visit);
		setRefresh(true);
	}

	function deleteTas(task) {
		let newTasks = [...tasks] || [];
		const index = newTasks?.findIndex((x) => x?._id === task?._id);
		if (index !== -1) newTasks.splice(index, 1);

		setTasks(newTasks);
		deleteTaskFromBD(task);
		setRefresh(true);
	}

	function saveTask(task) {
		let newTasks = tasks?.length > 0 ? [...tasks] : [];
		
		const index = newTasks?.findIndex((x) => x?._id === task?._id);

		if (index === -1) newTasks.push(task);
		else newTasks[index] = task;

		setTasks(newTasks);
		saveTaskToBD(task);
		setRefresh(true);
	}

	function saveVisit(visit) {
		let newVisits = visits?.length > 0 ? [...visits] : [];
		const index = newVisits?.findIndex((x) => x?._id === visit?._id);

		if (index === -1) newVisits.push(visit);
		else newVisits[index] = visit;

		setVisits(newVisits);
		saveVisitToBD(visit);
		setRefresh(true);
	}

	return (
		<>
			<Card>
				<CardHeader title="Calendar">
				</CardHeader>
				<CardBody>
					{!refresh && (<FullCalendarView 
					setDate={setDate}
					setSelectedEvent={setSelectedVisit}
					setTypeEvent={setTypeEvent}
					visits={visits}
					setVisits={setVisits}
					tasks={tasks}
					setTasks={setTasks}
					setOpenDialogs={setOpenCalendarDialogs}
					setInitialDate={setInitialDate}
					initialDate={initialDate}
					/>)}
					{openCalendarDialogs && (
						<CalendarDialogs 
							visits={visits}
							setOpen={setOpenCalendarDialogs}
							open={openCalendarDialogs}
							userId={selectedVisit?.user? selectedVisit?.user : null}
							users={users}
							date={date}
							typeEvent={typeEvent}
							setSelectedEvent={setSelectedVisit}
							selectedEvent={selectedVisit}
							visitTypes={visitTypes}
							setTypeEvent={setTypeEvent}
							onSave={(event, typeEvent) => {
								if (typeEvent === 1) saveVisit(event);
								else if (typeEvent === 2) saveTask(event);
								setTypeEvent(0);
							}}
							onDelete={(event, typeEvent) => {
								if (typeEvent === 1) deleteVis(event);
								else if (typeEvent === 2) deleteTas(event);
							}}
						/>
					)}
				</CardBody>
			</Card>
		</>
	);
}
