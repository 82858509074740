import React from 'react';
import { TextField } from "@material-ui/core";

//Si hay un editor, specific debe ser = false
const OnlyTextDate = React.memo(({title, element, handleChange, data, specific}) => {
    if (!data) return(<></>)
    return (
        <>
            <TextField
                id={`${element}`}
                label={title}
                value={data[element]}
                onChange={handleChange(element)}
                InputLabelProps={{
                shrink: true
                }}
                margin="normal"
                variant="standard"
                type='datetime-local'
                required
            />
        </>
    )
}, (prevProps, nextProps) => {
    if (!prevProps.data || !nextProps.data) return false;
    if (prevProps?.specific === undefined || prevProps?.specific) return prevProps.data[prevProps.element] === nextProps.data[nextProps.element];
    return prevProps.data === nextProps.data;
});

export default OnlyTextDate;