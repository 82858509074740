import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SERVER_URL } from "../../../../api";
import {
	changeStatusProductsPatient,
	deleteProductsPatient,
	getProductsPatient,
	updateProductsPatient,
} from "../../../../api/productsPatient";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import _ from 'lodash';

function getData(productsPatient) {
	let data = [];
	for (let i = 0; i < productsPatient.length; ++i) {
		const elem = {};
		const actP = productsPatient[i];

		elem.fullName = actP.fullName.es;
		elem._id = actP._id;
		elem.active = actP.active;
		elem.number = actP.number
		data = data.concat(elem);
	}
	return data;
}

export default function ProductsPatientPage() {
	const [data, setData] = useState([]);
	const [selectedProductPatient, setSelectedProductPatient] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	useEffect(() => {
		if (data?.length === 0) 
			getProductsPatient()
				.then((res) => {
					if (res.status === 200) {
						setData(res.data);
						setRefresh(false);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get products patient.",
					});
				});
		setRefresh(false);
	}, [refresh]);

	const columns = [
		{ dataField: "fullName", text: "Name" },
		{ 
			dataField: "_id",
			text: "", 
			align: 'right',
			headerAlign: 'right',
			formatter: buttonFormatter
		},
	];

	function buttonFormatter(cell) {
		const elem = data.find((item) => item._id === cell);
		const index = elem.number;
		// console.log(index, elem)
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() =>
							history.push("/edit-products-patient/" + cell)
						}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Move up">
							<Button
								size="small"
								style={buttonsStyle}
								disabled={index === 0}
								onClick={() => productMove(elem, index, index - 1)}
							>
								<ArrowUpward />
							</Button>
						</Tooltip>
						<Tooltip title="Move down">
							<Button
								size="small"
								disabled={
									index >= data?.length - 1
								}
								style={buttonsStyle}
								onClick={() => productMove(elem, index, index+1)}
							>
								<ArrowDownward />
							</Button>
						</Tooltip>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setSelectedProductPatient(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {	
									setSelectedProductPatient(elem);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	function deleteProduct() {	
		let newData = [...data];
		const index = selectedProductPatient?.number;
		newData.splice(index, 1);

		for (let i = index; i < newData?.length; ++i) {
			newData[i].number = newData[i].number - 1;
			saveProduct(newData[i]);
		}

		deleteProductsPatient(selectedProductPatient?._id)
			.then((res) => {
				if (res.status === 204 ||res.status === 200) {
					alertSuccess({
						title: "Deleted!",
						customMessage:
							"Product patient removed successfully.",
					});
					setRefresh(true);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage:
						"Could not remove products patient.",
				});
			});
		
		setData([...newData])
		setRefresh(true);
	}

	function productMove(elem, index, newIndex) {
		let newData = [...data];
		const productUp = newData.find(
			(x) => x.number === newIndex
		)
		console.log(productUp, elem)
		
		newData[newIndex] = {
			...elem,
			number: newIndex
		};
		newData[index] = {
			...productUp,
			number: index
		}

		setData([...newData]);
		saveProduct(newData[newIndex]);
		saveProduct(newData[index]);
		setRefresh(true)
	}

	function saveProduct(product) {
		if (product?._id)
			updateProductsPatient(product?._id, product)
				.then((res) => {
					
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update product"
					})
				})
	}

	return (
		<>
			<Card>
				<CardHeader title="Products patient list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-products-patient")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					{!refresh && (<Table data={getData(data)} columns={columns} />)}
					<ConfirmDialog
						title={`Are you sure you want to ${
							selectedProductPatient?.active ? "disable" : "enable"
						} this products patient?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusProductsPatient(
								selectedProductPatient._id,
								!selectedProductPatient?.active
							)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												selectedProductPatient?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Food family ${
												selectedProductPatient?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											selectedProductPatient?.active
												? "disable"
												: "enable"
										} products patient.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={
							"Are you sure you want to remove this products patient?"
						}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteProduct(selectedProductPatient)
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
