import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const getUserDocuments = () => {
	return authClient().get(`${API}/user-documents?getDisabled=true`);
};

export const getUserDocumentsById = (id) => {
	return authClient().get(`${API}/user-documents/${id}`);
};

export const getUserDocumentsByUserId = (userId) => {
	return authClient().get(`${API}/user-documents/user/${userId}`);
};

export const deleteUserDocuments = (id) => {
	return authClient().delete(`${API}/user-documents/${id}`);
};

export const postUserDocuments = async (userDocuments, indexDocument, file) => {
	if (file) {
		const response = await uploadSingleFile(file, "usersDocuments")
		console.log(response)
		userDocuments.documents[indexDocument].fileURL = response.data.fileURL; 
	}
	return authClient().post(`${API}/user-documents`, userDocuments);
};

export const updateUserDocuments = async (id, userDocuments, indexDocument, file) => {
	if (file) {
		const response = await uploadSingleFile(file, "usersDocuments")
		userDocuments.documents[indexDocument].fileURL = response.data.fileURL; 
	}
	return authClient().put(`${API}/user-documents/${id}`, {...userDocuments, indexDocument: indexDocument});
};

export const deleteUserDocument = async (id, userDocuments, indexDocument) => {
	return authClient().put(`${API}/user-documents/${id}/delete-one-document`, {...userDocuments, indexDocument: indexDocument});
};

export const changeStatusUserDocuments = async (id, active) => {
	return authClient().put(`${API}/user-documents/change-status/${id}`, {
		active,
	});
};