import React from 'react';
import { Row, Col } from "react-bootstrap";
import OnlyTextMult from './OnlyTextMult';

//Si hay un editor, specific debe ser = false
const RowColTextMult = React.memo(({title, element, handleChange, data, specific}) => {
    return (
        <>
            <Row>
                <Col>
                    <OnlyTextMult 
                    title={title}
                    element={element}
                    handleChange={handleChange}
                    data={data}
                    specific={specific}
                    />
                </Col>
            </Row>
        </>
    )
}, (prevProps, nextProps) => {
    if (prevProps?.specific === undefined || prevProps?.specific) return prevProps.data[prevProps.element] === nextProps.data[nextProps.element];
    return prevProps.data === nextProps.data;
});

export default RowColTextMult;