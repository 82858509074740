import { API, authClient } from "../index";

export const getUserCompetitions = () => {
	return authClient().get(`${API}/user-competitions?getDisabled=true`);
};

export const getUserCompetitionsById = (id) => {
	return authClient().get(`${API}/user-competitions/${id}`);
};

export const getUserCompetitionsByUserId = (userId) => {
	return authClient().get(`${API}/user-competitions/user/${userId}`);
};

export const getMonthUserCompetitions = (month) => {
	return authClient().get(`${API}/user-competitions/month/${month}`)
}

export const deleteUserCompetitions = (id) => {
	return authClient().delete(`${API}/user-competitions/${id}`);
};

export const postUserCompetitions = async (userCompetitions) => {
	return authClient().post(`${API}/user-competitions`, userCompetitions);
};

export const updateUserCompetitions = async (id, userCompetitions) => {
	return authClient().put(`${API}/user-competitions/${id}`, userCompetitions);
};

export const changeStatusUserCompetitions = async (id, active) => {
	return authClient().put(`${API}/user-competitions/change-status/${id}`, {
		active,
	});
};