import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { changeStatusCompetition, deleteCompetition, getCompetitions } from "../../../../api/competition";
import { getNonEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
	dateFormatter,
} from "../../../components/tables/table";

function getData(competitions) {
	let data = [];
	
	for (let i = 0; i < competitions.length; ++i) {
		const elem = {};
		const comp = competitions[i];

		elem.name = getNonEmpty(comp.fullName);
		elem.competitionType = getNonEmpty(comp.competitionType?.fullName);
		elem.totalKilometers = comp.totalKilometers;
		elem.date = comp.date;
		elem.createdAt = comp.createdAt;
		elem.id = comp._id;
		elem.active = comp.active;

		data = data.concat(elem);
	}

	data.sort((a, b) => {
		const nameA = a.name.toLowerCase();
		const nameB = b.name.toLowerCase();
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	});

	return data;
}

export default function CompetitionsPage() {
	const [data, setData] = useState([]);
	const [competitionId, setCompetitionId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	function buttonFormatter(cell) {
		const elem = data.find((item) => item.id === cell);
		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => history.push("/edit-competition/" + cell)}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title={elem?.active ? "Disable" : "Enable"}>
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setCompetitionId(elem);
									setOpenConfirmDialog(1);
								}}
							>
								{elem?.active ? (
									<ToggleOffIcon />
								) : (
									<ToggleOnIcon style={{ color: "red" }} />
								)}
							</Button>
						</Tooltip>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
									setCompetitionId(cell);
									setOpenConfirmDialog(2);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
			</>
		);
	}

	const columns = [
		{
			dataField: "name",
			text: "Full name",
			sort: true,
		},
		{
			dataField: "competitionType",
			text: "Competition Type",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "totalKilometers",
			text: "Total Km.",
			sort: true,
			headerAlign: "center",
			align: "center",
		},
		{
			dataField: "date",
			text: "date",
			sort: true,
			headerAlign: "center",
			align: "center",
			formatter: dateFormatter
		},
		{
			dataField: "createdAt",
			text: "Created at",
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: "id", text: "", formatter: buttonFormatter },
	];

	useEffect(() => {
		getCompetitions()
			.then((res) => {
				if (res.status === 200) {
					setData(getData(res.data));
					setRefresh(false);

					//Refresca por si acaso no se ha actualizado
					setTimeout(() => {
						getCompetitions()
						.then((res) => {
							if (res.status === 200) {
								setData(getData(res.data));
								setRefresh(false);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get competitions.",
							});
						});
					}, 600)

				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get competitions.",
				});
			});
	}, [refresh]);

	return (
		<>
			<Card>
				<CardHeader title="Competitions list">
					<CardHeaderToolbar>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push("/edit-competition")}
						>
							Add new
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<Table data={data} columns={columns} />
					<ConfirmDialog
						title={`Are you sure you want to ${
							competitionId?.active ? "disable" : "enable"
						} this competition?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusCompetition(competitionId.id, !competitionId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({
											title: `${
												competitionId?.active
													? "Disabled!"
													: "Enabled!"
											}`,
											customMessage: `Competition ${
												competitionId?.active
													? "disabled"
													: "enabled"
											} successfully`,
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: `Could not ${
											competitionId?.active
												? "disable"
												: "enable"
										} competition.`,
									});
								});
						}}
					/>
					<ConfirmDialog
						title={"Are you sure you want to remove this competition?"}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteCompetition(competitionId)
								.then((res) => {
									if (
										res.status === 204 ||
										res.status === 200
									) {
										alertSuccess({
											title: "Deleted!",
											customMessage:
												"Competition removed successfully.",
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: "Could not remove competition.",
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
