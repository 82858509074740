import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
	FormControlLabel,
	Checkbox
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteUser, getUserById, postUser, updateUser } from '../../../../api/user'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyAdmin() {
  return {
    fullName: '',
    email: '',
    role: 'admin',
    password: '',
    repeatPassword: '',
    active: true
  }
}

export default function EditAdminsPage() {
  const [admin, setAdmin] = useState(getEmptyAdmin())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(0)
  const [newPassword, setNewPassword] = useState({ password: null, repeatPassword: null })
	const [changePassword, setChangePassword] = useState(false)
  const adminId = useParams().id
  const history = useHistory()

  const [changes, setChanges] = useState(-2);

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!adminId) {
      disableLoadingData()
      return
    }
    getUserById(adminId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        setAdmin(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get admin.' })
      history.push('/admins')
    })
  }, [adminId, disableLoadingData, history])

  useEffect(() => {
    if (!adminId) setChanges(changes+2);
		else setChanges(changes+1);
  }, [admin])

  function saveAdmin() {
		let saveAdmin = admin
		if (!adminId || changePassword) {
		  if (!newPassword.password || !newPassword.repeatPassword) {
        alertError({ error: null, customMessage: 'Please enter the password.' })
        return
		  }
		  if (newPassword.password !== newPassword.repeatPassword) {
        alertError({ error: null, customMessage: 'Passwords do not match.' })
        return
		  }
		  saveAdmin = {...saveAdmin, password: newPassword.password }
		}
    if (!adminId) {
      postUser(saveAdmin).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Admin successfully created.' })
          history.push('/admins')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save admin.' })
      })
    } else {
      updateUser(adminId, saveAdmin).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/admins')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setAdmin({ ...admin, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
      <CardHeader title={admin?._id?'Admin: ' + (admin.fullName?admin.fullName:''):'New admin'}>
        <div className='mt-5'>
          <Button
            onClick={() => {
              if (changes > 0) setOpenConfirmDialog(1);
              else history.push("/admins");
            }}
            variant="outlined"
            style={{ marginRight: '20px' }}>
            Back
          </Button>
          <Button
            onClick={() => saveAdmin()}
            variant="outlined"
            color="primary"
            style={{ marginRight: '20px' }}>
            Save admin
          </Button>
          {adminId && <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(2)}
                variant="outlined"
                color="secondary">
                Delete admin
              </Button>
            </MuiThemeProvider>
            <ConfirmDialog
              title={
                "Are you sure you want to go back? You will lose all your changes"
              }
              open={openConfirmDialog === 1}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                history.push("/admins")
              }}
            />
            <ConfirmDialog
              title={'Are you sure you want to delete this admin?'}
              open={openConfirmDialog === 2}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteUser(adminId).then(res => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({ title: 'Deleted!', customMessage: 'Admin deleted successfully' })
                    history.push('/admins')
                  }
                }).catch(error => {
                  alertError({ error: error, customMessage: 'Could not delete admin.' })
                })
              }}
            />
          </>}
        </div>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={admin.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="standard"
            required
          />
          <TextField
            id={`email`}
            label="Email"
            value={admin.email}
            onChange={handleChange('email')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="standard"
            required
          />
						{(!adminId || changePassword) ? <>
							<br />
							<br />
							<TextField
								id={`password`}
								label="Password"
								value={newPassword.password}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, password: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="standard"
								required
							/>
							<TextField
								id={`repeatPassword`}
								label="Repeat password"
								value={newPassword.repeatPassword}
								onChange={(event) => {
									if (event.target.value !== ' ') setNewPassword({ ...newPassword, repeatPassword: event.target.value })
								}}
								InputLabelProps={{
									shrink: true
								}}
								type="password"
								margin="normal"
								variant="standard"
								required
							/>
							<br/>
							<br/>
							{adminId && <>
								<Button
									onClick={() => {
										setChangePassword(false)
										setNewPassword({ password: null, repeatPassword: null })
									}}
									variant="standard"
									style={{ marginRight: '20px' }}
								>
									Cancel change password
								</Button>
								<br />
								<br />  
							</>}
							</> : <>
							<br />
							<br />
							<Button
								onClick={() => setChangePassword(true)}
								variant="standard"
								color="primary"
								style={{ marginRight: '20px' }}
							>
								Change password
							</Button>
							<br />
							<br />
						</>}
						<br/>
						<FormControlLabel
							control={<Checkbox checked={admin.active} onChange={() => setAdmin({ ...admin, active: !admin.active })} name="checkActive" />}
							label="Active"
						/>
        </CardBody>
      </Card>
    </>
  );
}
