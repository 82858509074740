export function getEmptyVisit(date, userId) {
    let formatDate;
    if (date) {
        const newDate = new Date(date);
        const now = new Date(); 
        newDate.setHours(now.getHours() + 6);
        newDate.setMinutes(now.getMinutes());
        newDate.setSeconds(now.getSeconds());
        newDate.setMilliseconds(0);
        newDate.setDate(newDate.getDate() - 1);
        formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 600000).toISOString().split('.')[0];
    }
    else {    
        const newDate = new Date();
        formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    }
    return {
        fullName: '',
        visitType: null,
        user: userId || null,
        visitDate: formatDate,
        progress: null,
        planOfAction: null,
        internalNotes: null,
        patientReport: null,
        active: true
    };
}