import { Button, MuiThemeProvider, createMuiTheme, } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog';
import { shallowEqual, useSelector } from 'react-redux';
import OnlyTextDate from '../../../../components/textFields/OnlyTextDate';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';
import { deleteUserSweatTests, postUserSweatTests, updateUserSweatTests } from '../../../../../api/userSweatTests';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import { losedWeight, perLosedWeight, sweatRate } from '../../../../../utils/mesuresCalcs';
import OnlyNumberRead from '../../../../components/textFields/OnlyNumberRead';
import EfficientlessText from '../../../../components/textFields/EfficientlessText';

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyTest(patientId) {
    const today = new Date();
    const date = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('.')[0];
    return {
        userId: patientId,
        date: date,
        averageTemperature: 0,
        realizedActivity: null,
        trainingIntensity: null,
        trainingDuration: 0,
        preTrainingWeight: 0,
        postTrainingWeight: 0,
        intakeWeight: 0,
        urinations: 0,
        depositions: 0,
        observations: null,
        active: true
    }
}

export default function EditPatientSweatTest(props) {
    const { patientId, sweatTest, setSweatTest, setOpen } = props;

    const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
    const [changes, setChanges] = useState(-2);

    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {
        if (!sweatTest) setSweatTest(getEmptyTest(patientId));
        else {
            let newSweatTest = {...sweatTest}
            newSweatTest.date = getDateFormat(sweatTest);
            newSweatTest = refreshCalcs(newSweatTest);
            setSweatTest({...newSweatTest});
        }
    }, [])

    useEffect(() => {
        if (changes <= 0) setChanges(changes+1)
    }, [sweatTest])

    function getDateFormat(test) {
        const testDate = new Date(test.date);
        const date = new Date(testDate.getTime() - testDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        return date;
    }

    function refreshCalcs(test) {
        test.losedWeight = losedWeight(test.preTrainingWeight, test.postTrainingWeight, 
            test.intakeWeight, test.urinations, test.depositions);
        test.perLosedWeight = perLosedWeight(test.preTrainingWeight, test.postTrainingWeight);
        test.sweatRate = sweatRate(test.losedWeight, test.trainingDuration);
        return test;
    }

    const handleChange = (element) => (event) =>{
        const value = event.target.value;
        setSweatTest((prevSweatTest) => {
            let test = {...sweatTest, [element]: value};  
            test = refreshCalcs(test);
            return test;
        });
    } 

    function saveTest(test) {
        function alert(required) {
            alertError({
                error: null,
                customMessage: `${required} path is required` 
            })
        }
        if (!test?.place) {alert("Place"); return;}
        if (!test?.realizedActivity) {alert("Realized activity"); return;}
        if (!test?.trainingIntensity) {alert("Training intensity"); return;}
        if (test?._id)
            updateUserSweatTests(test?._id, test)
                .then((res) => {
                    alertSuccess({
                        title: "Saved!",
                        customMessage: "Sweat test updated."
                    })
                    onClose();
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not update sweat test."
                    })
                })
        else postUserSweatTests(test)
                .then((res) => {
                    alertSuccess({
                        title: "Saved!",
                        customMessage: "Sweat test updated."
                    })
                    onClose();
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not update sweat test."
                    })
                })
    }

    function onClose() {
        setOpen(false);
        setChanges(0);
    }

    return (
        <Card>
            <CardHeader title="Edit sweat test">
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            if (changes > 0) setOpenConfirmDialog(1);
                            else onClose();
                        }}
                        variant="outlined"
                        style={{ marginRight: "20px" }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => saveTest(sweatTest)}
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: "20px" }}
                    >
                        Save sweat test
                    </Button>
                    <ConfirmDialog
                        title={
                            "Are you sure you want to go back? You will lose all your changes"
                        }
                        open={openConfirmDialog === 1}
                        setOpen={setOpenConfirmDialog}
                        onConfirm={() => {
                            onClose();
                        }}
                    />
                    {sweatTest?._id && user?.role.includes("admin") && (
                        <>
                            <MuiThemeProvider theme={theme}>
                                <Button
                                    onClick={() => setOpenConfirmDialog(2)}
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginRight: "20px" }}
                                >
                                    Delete sweat test
                                </Button>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                    }}
                                ></div>
                            </MuiThemeProvider>

                            <ConfirmDialog
                                title={
                                    "Are you sure you want to delete this sweat test?"
                                }
                                open={openConfirmDialog === 2}
                                setOpen={setOpenConfirmDialog}
                                onConfirm={() => {
                                    deleteUserSweatTests(sweatTest?._id)
                                        .then((res) => {
                                            if (res.status === 204 || res.status === 200) {
                                                alertSuccess({
                                                    title: "Deleted!",
                                                    customMessage:
                                                        "Sweat test deleted successfully.",
                                                });
                                                onClose();
                                            }
                                        })
                                        .catch((error) => {
                                            alertError({
                                                error: error,
                                                customMessage:
                                                    "Could not delete sweat test.",
                                            });
                                        });
                                }}
                            />
                        </>
                    )}
                </div>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={6}><EfficientlessText title={"Place"} element={"place"} handleChange={handleChange} data={sweatTest} type={"text"}/></Col>
                    <Col sm={6}><OnlyTextDate title={"Date"} element={"date"} handleChange={handleChange} data={sweatTest} specific={false}/> </Col>
                </Row>
                <Row>
                    <Col sm={2}><EfficientlessText title={"Average temperature"} element={"averageTemperature"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={2}><EfficientlessText title={"Realized activity"} element={"realizedActivity"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={2}><EfficientlessText title={"Training intensity"} element={"trainingIntensity"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={2}><EfficientlessText title={"Training duration (h)"} element={"trainingDuration"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={2}><EfficientlessText title={"Pre training weight"} element={"preTrainingWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={2}><EfficientlessText title={"Post training weight"} element={"postTrainingWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                </Row>
                <Row>
                    <Col sm={2}><EfficientlessText title={"Intake weight"} element={"intakeWeight"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={2}><EfficientlessText title={"Urinations"} element={"urinations"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={2}><EfficientlessText title={"Depositions"} element={"depositions"} handleChange={handleChange} data={sweatTest} type={"number"}/></Col>
                    <Col sm={6}><EfficientlessText title={"Observations"} element={"observations"} handleChange={handleChange} data={sweatTest} /></Col>
                </Row>
                <br />
                <h5>Test calcs</h5>
                <Row>
                    <Col sm={4}><OnlyNumberRead title={"Losed weight"} element={"losedWeight"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={4}><OnlyNumberRead title={"%Losed weight"} element={"perLosedWeight"} handleChange={handleChange} data={sweatTest} /></Col>
                    <Col sm={4}><OnlyNumberRead title={"Sweat rate"} element={"sweatRate"} handleChange={handleChange} data={sweatTest} /></Col>
                </Row>
            </CardBody>
            
        </Card>
    )
}