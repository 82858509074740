import React, { useState, useEffect } from 'react'
import { deleteUserSweatTests, getUserSweatTestsByUserId } from '../../../../../api/userSweatTests';
import { alertError, alertSuccess } from '../../../../../utils/logger';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Tooltip } from "@material-ui/core";
import ConfirmDialog from "../../../../components/dialogs/ConfirmDialog";
import Table, {
	buttonsStyle,
} from "../../../../components/tables/table";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import EditPatientSweatTest from './EditPatientSweatTest';
import EditPatientIsakTest from './EditPatientIsakTest';
import { deleteUserIsakTests, getUserIsakTestsByUserId } from '../../../../../api/userIsakTests';

export default function EditPatientMeasures(props) {
    const { patientId, patient } = props;

    const [sweatTests, setSweatTests] = useState([]);    
    const [openEditSweatTest, setOpenEditSweatTest] = useState(false);
    const [selectedSweatTest, setSelectedSweatTest] = useState(null);
    
    const [isakTests, setIsakTests] = useState([]);    
    const [openEditIsakTest, setOpenEditIsakTest] = useState(false);
    const [selectedIsakTest, setSelectedIsakTest] = useState(null);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
    const [refresh, setRefresh] = useState(false);
    const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

    useEffect(() => {
        if (!openEditSweatTest) refreshSweatTests();
    }, [openEditSweatTest])

    
    useEffect(() => {
        if (!openEditIsakTest) refreshIsakTests();
    }, [openEditIsakTest])

    useEffect(() => {
        setRefresh(false);
    }, [refresh])

    function refreshSweatTests() {
        getUserSweatTestsByUserId(patientId)
            .then((res) => {
                if (res.status === 200) {
                    setSweatTests(res.data);
                    setRefresh(true)
                }
            })
            .catch((error) => {
                if (error?.request?.status !== 404) {
                    alertError({
                        error: error,
                        customMessage: "Could not get sweat tests."
                    })
                }
            })
    }

    function refreshIsakTests() {
        getUserIsakTestsByUserId(patientId)
            .then((res) => {
                if (res.status === 200) {
                    setIsakTests(res.data);
                    setRefresh(true)
                }
            })
            .catch((error) => {
                if (error?.request?.status !== 404) {
                    alertError({
                        error: error,
                        customMessage: "Could not get isak tests."
                    })
                }
            })
    }

    const sweatTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "date",
            text: "hour",
            align: 'left',
            headerAlign: 'left',
            formatter: hourFormatter
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: sweatTestbuttonFormatter
        }
    ]

    const isakTestsColumns = [
        {
            dataField: "date",
            text: "date",
            formatter: dateFormatter
        },
        {
            dataField: "date",
            text: "hour",
            align: 'left',
            headerAlign: 'left',
            formatter: hourFormatter
        },
        {
            dataField: "_id",
            text: "",
            align: "right",
            formatter: isakTestbuttonFormatter
        }
    ]

    function dateFormatter(cell) {
        const date = new Date(cell);
        
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    function hourFormatter(cell) {
        const date = new Date(cell);
        const hour = date.getHours();
        const min = date.getMinutes();
        const sec = date.getSeconds();
        return `${hour}:${min}:${sec}`;
    }

    function sweatTestbuttonFormatter(cell, row) {
        const elem = sweatTests?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditSweatTest(true);
                            setSelectedSweatTest(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(2);
                                    setSelectedSweatTest(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }

    function isakTestbuttonFormatter(cell, row) {
        const elem = isakTests?.find((x) => x._id === cell);

        return (
            <>
                <Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
                            setOpenEditIsakTest(true);
                            setSelectedIsakTest(elem);
                        }}
					>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role.includes("admin") && (
					<>
						<Tooltip title="Delete">
							<Button
								style={buttonsStyle}
								size="small"
								onClick={() => {
                                    setOpenConfirmDialog(3);
                                    setSelectedIsakTest(elem);
								}}
							>
								<DeleteIcon />
							</Button>
						</Tooltip>
					</>
				)}
            </>
        )
    }

    function getSweatTestsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.date = actT?.date;

            data.push(elem);
        }

        return data;
    }

    function getIsakTestsData(tests) {
        let data = [];

        for (let i = 0; i < tests?.length; ++i) {
            let elem = {};
            const actT = tests[i];

            elem._id = actT?._id;
            elem.date = actT?.date;

            data.push(elem);
        }

        return data;
    }

    if (openEditSweatTest) return(
        <EditPatientSweatTest 
        patientId={patientId}
        sweatTest={selectedSweatTest}
        setSweatTest={setSelectedSweatTest}
        setOpen={setOpenEditSweatTest}
        />
    )
    else if (openEditIsakTest) return(
        <EditPatientIsakTest 
        patientId={patientId}
        patient={patient}
        isakTest={selectedIsakTest}
        setIsakTest={setSelectedIsakTest}
        setOpen={setOpenEditIsakTest}
        />
    )
    return (
        <Card>
            <ConfirmDialog
                title={
                    "Are you sure you want to delete this sweat test?"
                }
                open={openConfirmDialog === 2}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    deleteUserSweatTests(selectedSweatTest?._id)
                        .then((res) => {
                            if (res.status === 204 || res.status === 200) {
                                alertSuccess({
                                    title: "Deleted!",
                                    customMessage:
                                        "Sweat test deleted successfully.",
                                });
                                refreshSweatTests();
                            }
                        })
                        .catch((error) => {
                            alertError({
                                error: error,
                                customMessage:
                                    "Could not delete sweat test.",
                            });
                        });
                }}
            />
            <ConfirmDialog
                title={
                    "Are you sure you want to delete this isak test?"
                }
                open={openConfirmDialog === 3}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    deleteUserIsakTests(selectedIsakTest?._id)
                        .then((res) => {
                            if (res.status === 204 || res.status === 200) {
                                alertSuccess({
                                    title: "Deleted!",
                                    customMessage:
                                        "Isak test deleted successfully.",
                                });
                                refreshIsakTests();
                            }
                        })
                        .catch((error) => {
                            alertError({
                                error: error,
                                customMessage:
                                    "Could not delete isak test.",
                            });
                        });
                }}
            />
            <CardHeader title="Sweat Tests">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setOpenEditSweatTest(true);
                            setSelectedSweatTest(null);
                        }}
                    >
                        Add new
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {!refresh && <Table data={getSweatTestsData(sweatTests)} columns={sweatTestsColumns}/>}
            </CardBody>

            <CardHeader title="Isak Tests">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setOpenEditIsakTest(true);
                            setSelectedIsakTest(null);
                        }}
                    >
                        Add new
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {!refresh && <Table data={getIsakTestsData(isakTests)} columns={isakTestsColumns}/>}
            </CardBody>
        </Card>
    )
}