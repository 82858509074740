import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const getFoodPlateStructures = () => {
	return authClient().get(`${API}/food-plate-structure?getDisabled=true`);
};

export const getFoodPlateStructureById = (id) => {
	return authClient().get(`${API}/food-plate-structure/${id}`);
};

export const getFoodPlateStructuresByStructureId = (id, type) => {
	return authClient().get(`${API}/food-plate-structure/${id}/${type}`);
};

export const deleteFoodPlateStructure = (id) => {
	return authClient().delete(`${API}/food-plate-structure/${id}`);
};

export const postFoodPlateStructure = async (foodPlate) => {
	return authClient().post(`${API}/food-plate-structure`, foodPlate);
};

export const updateFoodPlateStructure = async (id, foodPlate) => {
	return authClient().put(`${API}/food-plate-structure/${id}`, foodPlate);
};

export const changeStatusFoodPlateStructure = async (id, active) => {
	return authClient().put(`${API}/food-plate-structure/change-status/${id}`, {
		active,
	});
};

// Count all competitions
export const countFoodPlateStructures = () => {
	return authClient().get(`${API}/food-plate-structure/count/`)
}