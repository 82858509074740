import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getUsers, deleteUser, changeStatusUser } from '../../../../api/user'
import { Button, Tooltip, Switch, FormControlLabel } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import FiltersCard from '../../../components/filters/Filter';

function getData(patients) {
  let data = []
  for (let i = 0; i < patients.length; ++i) {
    if (patients[i].role === 'patient') {
      const elem = {}
      elem.name = patients[i].name
      elem.surname = patients[i].surname
      elem.gender = patients[i].gender
      elem.age = patients[i].birthdate
	  elem.lastWeight = '62.48'
	  elem.nextVisit = '01/01/2024 12:00'
	  elem.phone = patients[i].phone
      elem.createdAt = patients[i].createdAt
      elem.id = patients[i]._id
      elem.active = patients[i].active;
      data = data.concat(elem)
    }
  }
  return data
}

const initialFilters = {
	active: false
}

export default function PatientsPage() {
  const [data, setData] = useState([]);
  const [patientId, setPatientId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [collapsed, setCollapsed] = useState(true)
  const [filterOptions, setFilterOptions] = useState(initialFilters)
  const history = useHistory()
  const user = useSelector((store) => store.authentication?.user, shallowEqual)

  function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title='Edit'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-patient/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				<Tooltip title={elem?.active ? "Disable" : "Enable"}>
					<Button
						style={buttonsStyle} size="small"
						onClick={() => {
							setPatientId(elem)
							setOpenConfirmDialog(1)
						}}>
						{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{ color: 'red' }} />}
					</Button>
				</Tooltip>
				<Tooltip title='Delete'>
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => {
							setPatientId(cell);
							setOpenConfirmDialog(2);
						}}>
						<DeleteIcon />
					</Button>
				</Tooltip>
			</>
		);
	}

	function weightFormatter(cell) {
		return `${parseFloat(cell).toFixed(1)} Kg`;
	}

	function ageFormatter(cell) {
		const birthDate = new Date(cell);
		const today = new Date();

		let age = today.getFullYear() - birthDate.getFullYear();
		const monthDifference = today.getMonth() - birthDate.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		
		return age;
	}
	
	const columns = [
		{ dataField: 'name', text: 'Name', sort: true },
		{ dataField: 'surname', text: 'Surname', sort: true },
		{ dataField: 'gender', text: 'Gender', sort: true },
		{ dataField: 'age', text: 'Age', sort: true, formatter: ageFormatter },
		{ dataField: 'lastWeight', text: 'Last Weight', sort: true, formatter: weightFormatter },
		{ dataField: 'nextVisit', text: 'Next visit', sort: true },
		{ dataField: 'phone', text: 'Phone', sort: true },
		{ dataField: 'createdAt', text: 'Created at', formatter: dateFormatter, sort: true },
		{ dataField: 'id', text: '', formatter: buttonFormatter }
	]

	useEffect(() => {
		getUsers().then((res) => {
			if (res.status === 200) {
				setData(res.data)
				setFilteredData(res.data)
				setUsers(res.data)
				setRefresh(false)
			}
			}).catch(error => {
				alertError({ error: error, customMessage: 'Could not get patients.' })
			})
	}, [refresh])

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.active) filter = filter && item.active
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControlLabel
				control={<Switch checked={filterOptions.active} onChange={() => setFilterOptions({ ...filterOptions, active: !filterOptions.active })} name="checkActive" />}
				label={filterOptions.active ? "Only active" : "All active/inactive"}
			/>
		</>
	}

  return (
    <>
      <Card>
        <CardHeader title='Patients list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-patient')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
			<FiltersCard 
				filtersContent={renderFiltersContent}
				collapsed={collapsed} 
				setCollapsed={setCollapsed}
				handleClearFilters={handleClearFilters}
				handleSearch={handleSearch}
			/>

			<Table data={getData(filteredData, users)} columns={columns} />

          	<ConfirmDialog
				title={`Are you sure you want to ${patientId?.active ? 'disable' : 'enable'} this patient?`}
				open={openConfirmDialog === 1}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					changeStatusUser(patientId.id, !patientId?.active)
						.then((res) => {
							if (res.status === 200) {
								alertSuccess({ title: `${patientId?.active ? 'Disabled!' : 'Enabled!'}`, customMessage: `Patient ${patientId?.active ? 'disabled' : 'enabled'} successfully` })
								setRefresh(true);
							}
						})
						.catch((error) => {
							alertError({ error: error, customMessage: `Could not ${patientId?.active ? 'disable' : 'enable'} patient.` })
						});
				}}
			/>
			<ConfirmDialog
				title={'Are you sure you want to remove this patient?'}
				open={openConfirmDialog === 2}
				setOpen={setOpenConfirmDialog}
				onConfirm={() => {
					deleteUser(patientId)
						.then((res) => {
							if (res.status === 204 || res.status === 200) {
								alertSuccess({
									title: 'Deleted!',
									customMessage: 'Patient removed successfully.',
								});
								setRefresh(true);
							}
						})
						.catch((error) => {
							alertError({
								error: error,
								customMessage: 'Could not remove patient.',
							});
						});
				}}
			/>
        </CardBody>
      </Card>
    </>
  );
}
