import React from 'react';
import { TextField } from "@material-ui/core";

//Si hay un editor, specific debe ser = false
const EfficientlessText = ({title, element, handleChange, data, type}) => {
    if (!data) return(<></>)
    return (
        <>
            <TextField
                id={`${element}`}
                label={title}
                value={data[element]}
                onChange={handleChange(element)}
                InputLabelProps={{
                shrink: true
                }}
                margin="normal"
                variant="standard"
                type={type || "text"}
                required
            />
        </>
    )
}

export default EfficientlessText;