import {
	Button,
	Checkbox,
	createMuiTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	MuiThemeProvider,
	TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty, getNonEmpty } from "../../../utils/helpers";
import { alertError } from "../../../utils/logger";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { getVisitTypes } from "../../../api/visitType";
import { getUserById } from "../../../api/user";
import VisitsInformation from "../../modules/ContentManager/visits/VisitsInformation";
import { getLastVisit } from "../../../api/visit";
import ConfirmDialog from "./ConfirmDialog";

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const EditVisitDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, visitTypes, 
        userId, users, date, predefPatient, deleteOption, onDelete } = props;

    const [user, setUser] = useState(null);
	const [visit, setVisit] = useState(getEmptyVisit());
    const [lastVisit, setLastVisit] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);
	const history = useHistory();

    function getEmptyVisit() {
        let formatDate;
        if (date) {
            const newDate = new Date(date);
            const now = new Date(); 
            newDate.setHours(now.getHours() + 6);
            newDate.setMinutes(now.getMinutes());
            newDate.setSeconds(now.getSeconds());
            newDate.setMilliseconds(0);
            newDate.setDate(newDate.getDate() - 1);
            formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 600000).toISOString().split('.')[0];
        }
        else {    
            const newDate = new Date();
            formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
        }
        return {
            fullName: '',
            visitType: null,
            user: userId || null,
            visitDate: formatDate,
            progress: null,
            planOfAction: null,
            internalNotes: null,
            patientReport: null,
            active: true
        };
    }
    

	useEffect(() => {
		if (!open || !data) setVisit(getEmptyVisit());
        else {
            let newData = data;
            if (newData?.visitDate) {
                let visitDate = new Date(newData.visitDate);
                newData.visitDate = new Date(visitDate.getTime() - visitDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
            }
            setVisit(data);
        }
	}, [data, open]);

    useEffect(() => {
        if (!visit.user) return;

        if (!user) {
            getUserById(visit.user)
                .then((res) => {
                    if (res.status === 200) {
                        setUser(res.data);
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get user",
                    });
                });
            
            getLastVisit(visit.user)
                .then((res) => {
                    if (res.status === 200) {
                        setLastVisit(res.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }, [visit])

    return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
            <ConfirmDialog 
                title={
                    "Are you sure you want to copy the visit? You will lose all youre changes"
                }
                open={openConfirmDialog === 2}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    if (lastVisit) {
                        let newData = lastVisit;
                        if (!visit?.visitDate) {
                            let newDate = new Date()
                            newData.visitDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
                        } else newData.visitDate = visit.visitDate;
                        delete newData?._id;
                        newData.patientReport = '';
                        setVisit({...newData});
                    } else {
                        alertError({
                            error: "The patient don't have visits",
                            customMessage: "Could not copy last visit"
                        })
                    }
                }}
            />
            <ConfirmDialog 
                title={
                    "Are you sure you want to copy the visit? You will lose all youre changes"
                }
                open={openConfirmDialog === 1}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    onDelete(visit);
                    setOpen(false);
                    setVisit(getEmptyVisit());
                }}
            />
			<DialogContent>
                <VisitsInformation 
                visit={visit}
                setVisit={setVisit}
                visitTypes={visitTypes}
                user={user}
                users={users? users : null}
                predefPatient={predefPatient? predefPatient : false}
                />
			</DialogContent>
			<DialogActions>
                {!visit?._id && user && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: '72%' }}
                        onClick={() => {
                            setOpenConfirmDialog(2);
                        }}
				    >
                        Copy last visit
                    </Button>
                )}
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setVisit(getEmptyVisit());
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
                    style={{ marginRight: "15px" }}
					onClick={() => {
						if (checkIsEmpty(visit.visitDate)) {
							alertError({
								error: null,
								customMessage:
									"The date is required",
							});
						} else if (!visit?.visitType) {
							alertError({
								error: null,
								customMessage:
									"The type is required",
							});
						} else {
							setOpen(false);
							onSave(visit);
							setVisit(getEmptyVisit());
						}
					}}
				>
					Save
				</Button>
                {visit?._id && deleteOption && (
                    <>
                    <MuiThemeProvider theme={theme}>
                        <Button
                        onClick={() => setOpenConfirmDialog(1)}
                        variant="outlined"
                        color="secondary"
                        >
                            Delete
                        </Button>
                    </MuiThemeProvider>
                        
                    </>
                    
                )}
                
			</DialogActions>
		</Dialog>
	);
};
export default EditVisitDialog;