import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postStructure = async (structure, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "structures");
		structure.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/structure`, structure);
};

export const getStructures = () => {
	return authClient().get(`${API}/structure?getDisabled=true`);
};

export const getStructureById = (id) => {
	return authClient().get(`${API}/structure/${id}`);
};

export const updateStructure = async (id, structure, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "structures");
		structure.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/structure/${id}`, structure);
};

export const changeStatusStructure = async (id, active) => {
	return authClient().put(`${API}/structure/change-status/${id}`, {
		active,
	});
};

export const deleteStructure = (id) => {
	return authClient().delete(`${API}/structure/${id}`);
};
