import { API, authClient } from "../index";

export const getUserDiets = () => {
	return authClient().get(`${API}/user-diets?getDisabled=true`);
};

export const getUserDietsById = (id) => {
	return authClient().get(`${API}/user-diets/${id}`);
};

export const getUserDietsByUserId = (userId) => {
	return authClient().get(`${API}/user-diets/user/${userId}`);
};

export const getUserDietsByDate = (date) => {
	return authClient().get(`${API}/user-diets/date/${date}`);
};

export const getUserDietsByUserIdAndDate = (userId, date) => {
	return authClient().get(`${API}/user-diets/user/${userId}/date/${date}`);
};

export const getUserDietsByUserIdAndDateWeek = (userId, date) => {
	return authClient().get(`${API}/user-diets/user/${userId}/date/${date}/week`);
};

export const deleteUserDiets = (id) => {
	return authClient().delete(`${API}/user-diets/${id}`);
};

export const postUserDiets = async (userDiets) => {
	return authClient().post(`${API}/user-diets`, userDiets);
};

export const updateUserDiets = async (id, userDiets) => {
	return authClient().put(`${API}/user-diets/${id}`, userDiets);
};

export const changeStatusUserDiets = async (id, active) => {
	return authClient().put(`${API}/user-diets/change-status/${id}`, {
		active,
	});
};