import { API, authClient } from "../index";

export const postProductsPatient = async (productsPatient) => {
	return authClient().post(`${API}/products-patient`, productsPatient);
};

export const getProductsPatient = () => {
	return authClient().get(`${API}/products-patient?getDisabled=true`);
};

export const getProductsPatientById = (id) => {
	return authClient().get(`${API}/products-patient/${id}`);
};

export const updateProductsPatient = async (id, productsPatient) => {
	return authClient().put(`${API}/products-patient/${id}`, productsPatient);
};

export const changeStatusProductsPatient = async (id, active) => {
	return authClient().put(`${API}/products-patient/change-status/${id}`, {
		active,
	});
};

export const countProductsPatient = () => {
	return authClient().get(`${API}/products-patient/count`);
};

export const deleteProductsPatient = (id) => {
	return authClient().delete(`${API}/products-patient/${id}`);
};
