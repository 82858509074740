import { API, authClient } from "../index";

export const getTasks = () => {
	return authClient().get(`${API}/task?getDisabled=true`);
};

export const getTaskById = (id) => {
	return authClient().get(`${API}/task/${id}`);
};

export const getMonthTasks = (month) => {
	return authClient().get(`${API}/task/month/${month}`)
}

export const deleteTask = (id) => {
	return authClient().delete(`${API}/task/${id}`);
};

export const postTask = async (task) => {
	return authClient().post(`${API}/task`, task);
};

export const updateTask = async (id, task) => {
	return authClient().put(`${API}/task/${id}`, task);
};

export const changeStatusTask = async (id, active) => {
	return authClient().put(`${API}/task/change-status/${id}`, {
		active,
	});
};
