export const SUCCESS = 'success'
export const ERROR = 'error'

export const DEVELOPMENT = process.env.NODE_ENV === 'development'

export const daysOfWeek = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

export const scheduleOptions = [
	"Morning",
	"Afternoon",
	"Night",
	"All day",
	"Holiday",
];