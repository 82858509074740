import { API, authClient } from "../index";

export const getTramsXUser = () => {
	return authClient().get(`${API}/tramXuser?getDisabled=true`);
};

export const getTramXUserById = (id) => {
	return authClient().get(`${API}/tramXuser/${id}`);
};

export const getTramsCompetitionUser = (competitionId, userId, date) => {
	return authClient().get(`${API}/tramXuser/${competitionId}/${userId}/${date}`);
};

export const deleteTramXUser = (id) => {
	return authClient().delete(`${API}/tramXuser/${id}`);
};

export const deleteSpecificsTramsXUser = (idCompetition, userId, date) => {
	return authClient().delete(`${API}/tramXuser/${idCompetition}/${userId}/${date}`);
};

export const postTramXUser = async (tramXuser) => {
	return authClient().post(`${API}/tramXuser`, tramXuser);
};

export const updateTramXUser = async (id, tramXuser) => {
	return authClient().put(`${API}/tramXuser/${id}`, tramXuser);
};

export const changeStatusTramXUser = async (id, active) => {
	return authClient().put(`${API}/tramXuser/change-status/${id}`, {
		active,
	});
};