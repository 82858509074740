import {
	Button,
	MuiThemeProvider,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	deleteVisit,
	getLastVisit,
	getVisitById,
	postVisit,
	updateVisit,
} from "../../../../api/visit";
import { getVisitTypes } from "../../../../api/visitType";
import { getPatients } from "../../../../api/user";
import { checkIsEmpty } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";
import VisitsInformation from "./VisitsInformation";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyVisit() {
	let newDate = new Date()
    let date = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
	return {
		fullName: '',
		visitDate: date,
		user: null,
		visitType: null,
		patientFeedback: '',
		expectedGoals: '',
		active: true,
	};
}

export default function EditVisitsPage() {
	const [users, setUsers] = useState([]);
	const [visitTypes, setVisitTypes] = useState([]);
	const [visit, setVisit] = useState(getEmptyVisit());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
    const [lastVisit, setLastVisit] = useState(null);
	const visitId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!visitId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [visit])

	useEffect(() => {
		if (users?.length === 0)
			getPatients()
				.then((res) => {
					if (res.status === 200) {
						setUsers(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get patients.",
					});
				});
		if (visitTypes?.length === 0)
			getVisitTypes()
				.then((res) => {
					if (res.status === 200) {
						setVisitTypes(res.data);
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not get visit types.",
					});
				});
		if (!visitId) {
			disableLoadingData();
			return;
		}
		getVisitById(visitId)
			.then((res) => {
				if (res.status === 200) {
                    let visitData = res.data;
                    if (visitData.visitDate) {
                        let visitDate = new Date(visitData.visitDate);
                        visitData.visitDate = new Date(visitDate.getTime() - visitDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
                    }
					setVisit(res.data);

					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get visit.",
				});
				history.push("/visits");
			});
	}, [visitId, disableLoadingData, history]);

	useEffect(() => {
		if (visit?.user) {
			getLastVisit(visit.user)
				.then((res) => {
					if (res.status === 200) {
						setLastVisit(res.data);
					}
				})
				.catch((error) => {
					console.log(error);
				})
		}
	}, [visit?.user])

	function saveVisit() {
		if (!visit?.user)
			return alertError({
				error: null,
				customMessage: "Patient is required.",
			});
		if (!visit?.visitType)
			return alertError({
				error: null,
				customMessage: "Visit type is required.",
			});
		if (!visitId) {
			postVisit(visit)
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Visit successfully created.",
						});
						history.push("/visits");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save visit.",
					});
				});
		} else {
			updateVisit(visitId, visit)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/visits");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={visit?._id?'Visit: ' + (visit.fullName?visit.fullName:''):'New visit'}>
					<div className="mt-5">	
						{!visit?._id && visit?.user && (
							<Button
								variant="outlined"
								color="secondary"
								style={{ marginRight: '20px' }}
								onClick={() => {
									setOpenConfirmDialog(3);
								}}
							>
								Copy last visit
							</Button>
						)}
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/visits");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveVisit()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save visit
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/visits")
							}}
						/>
						<ConfirmDialog 
							title={
								"Are you sure you want to copy the visit? You will lose all youre changes"
							}
							open={openConfirmDialog === 3}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								if (lastVisit) {
									let newData = lastVisit;
									if (!visit?.visitDate) {
										let newDate = new Date()
										newData.visitDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
									} else newData.visitDate = visit.visitDate;
									delete newData?._id;
									newData.patientReport = '';
									setVisit({...newData});
								} else {
									alertError({
										error: "The patient don't have visits",
										customMessage: "Could not copy last visit"
									})
								}
							}}
						/>

						{visitId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete visit
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this visit?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteVisit(visitId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Visit deleted successfully",
													});
													history.push("/visits");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete visit.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>					
						<VisitsInformation 
						users={users}
						visit={visit}
						setVisit={setVisit}
						visitTypes={visitTypes}
						/>
					</CardBody>
				</Card>
			</>
		);
}
