import { API, authClient } from "../index";

export const getUserSweatTests = () => {
	return authClient().get(`${API}/user-sweat-tests?getDisabled=true`);
};

export const getUserSweatTestsById = (id) => {
	return authClient().get(`${API}/user-sweat-tests/${id}`);
};

export const getUserSweatTestsByUserId = (userId) => {
	return authClient().get(`${API}/user-sweat-tests/user/${userId}`);
};

export const deleteUserSweatTests = (id) => {
	return authClient().delete(`${API}/user-sweat-tests/${id}`);
};

export const postUserSweatTests = async (userSweatTests) => {
	return authClient().post(`${API}/user-sweat-tests`, userSweatTests);
};

export const updateUserSweatTests = async (id, userSweatTests) => {
	return authClient().put(`${API}/user-sweat-tests/${id}`, userSweatTests);
};

export const changeStatusUserSweatTests = async (id, active) => {
	return authClient().put(`${API}/user-sweat-tests/change-status/${id}`, {
		active,
	});
};