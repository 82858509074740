import React from 'react';
import { TextField } from "@material-ui/core";

//Si hay un editor, specific debe ser = false
const OnlyTextRead = React.memo(({title, element, data, specific}) => {
    return (
        <>
            <TextField
                id={`${element}`}
                label={title}
                value={data[element] || "No definido"}
                InputLabelProps={{
                shrink: true
                }}
                inputProps={{readOnly: true}}
                margin="normal"
                variant="standard"
                className='readonly'
                type='text'
                required
            />
        </>
    )
}, (prevProps, nextProps) => {
    if (prevProps?.specific === undefined || prevProps?.specific) return prevProps.data[prevProps.element] === nextProps.data[nextProps.element];
    return prevProps.data === nextProps.data;
});

export default OnlyTextRead;