//SWEAT TESTS

import { calcIMC } from "./patientCalcs";

export function losedWeight(preWeight, postWeight, pIntake, m, depos) {
    const pPreN = parseFloat(preWeight);
    const pPostN = parseFloat(postWeight);
    const pIntakeN = parseFloat(pIntake);
    const mN = parseFloat(m);
    const deposN = parseFloat(depos);
    return (pPreN -  pPostN + (pIntakeN/1000) - (mN*0.25) - (deposN*0.2)).toFixed(1);
}

export function perLosedWeight(preWeight, postWeight) {
    const pPreN = parseFloat(preWeight);
    const pPostN = parseFloat(postWeight);
    if (pPostN === 0) return 0;
    return ((pPreN/pPostN)*100).toFixed(1);
}

export function sweatRate(losedW, time) {
    const losedWN = parseFloat(losedW);
    const timeN = parseFloat(time);
    if (timeN === 0) return 0;
    return ((losedWN/timeN)).toFixed(1);
}

//ISAK TESTS

export function calcMeasureError(measure1, measure2)
{
    const arithmeticMean = (measure1 + measure2)/2;
    const perError = (Math.abs((measure1 - measure2) / arithmeticMean)) * 100;
    return {error: perError, mean: arithmeticMean};
}

export function getType(element) {
    if (element.includes("Fold")) return "Fold";
    if (element.includes("Perimetral")) return "Perimetral";
    return null;
}

export function calcError(element, type) {
    const measure1 = element?.measure1;
    const measure2 = element?.measure2;
    const measure3 = element?.measure3;
    let total = element?.total;
    
    if (measure1 && measure2) {
        const res = calcMeasureError(measure1, measure2);
        if (res.error <= (type === "Fold" ? 5 : 1)) return {error: res.error, total: res.mean};
        else if (total) return {error: res.error, total: total}; //AQUI IRIA EL CALCULO SI EL ERROR FALLA
        else return {error: res.error, total: 0};
    }

    return {error: 0, total: null};
}

const substractAndDivideValues = {
    weight: {substract: 64.5, divide: 8},
    sitHeight: {substract: 89.92, divide: 4.5},

    tricepsFold: {substract: 15.4, divide: 4.47},
    subcapularFold: {substract: 17.2, divide: 5.07},
    bicepsFold: {substract: 8, divide: 2},
    iliacCrestFold: {substract: 22.4, divide: 6.8},
    supraspinalFold: {substract: 15.4, divide: 4.47},
    abdominalFold: {substract: 25.4, divide: 7.78},
    thighFold: {substract: 27, divide: 8.33},
    twinFold: {substract: 16, divide: 4.67},

    relaxedArmPerimetral: {substract: 26.89, divide: 2.33},
    contractedArmPerimetral: {substract: 29.41, divide: 2.37},
    waistPerimetral: {substract: 71.91, divide: 4.45},
    hipPerimetral: {substract: 94.67, divide: 5.58},
    thighPerimetral: {substract: 53.2, divide: 4.56},
    twinPerimetral: {substract: 35.25, divide: 2.3},
    
    humerusDiameter: {substract: 6.48, divide: 0.35},
    bistyloidDiameter: {substract: 5.21, divide: 0.28},
    femurDiameter: {substract: 9.52, divide: 0.48},

    fatMass: {substract: 12.13, divide: 3.25}
}

export function zValue(value, name, height) {
    if (value && height) {
        const subsAndDivide = substractAndDivideValues[name];
        if (subsAndDivide) {
            const pow3 = (170.18 / height)*(170.18 / height)*(170.18 / height);
            const sub = subsAndDivide.substract, div = subsAndDivide.divide;
            return parseFloat((value * pow3 - sub) / div).toFixed(2);
        }
    }
    return 0;
}

export function calcParticularValue(element, name, height) {
    let newElement = [...element];
    const type = getType(name);
    const res = calcError(newElement[0], type);
    newElement[0] = { ...newElement[0], error: res.error, total: res.total};
    if (res.total && height) newElement[0] = { ...newElement[0], zValue: zValue(res.total, name, height)}
    return newElement;
}

export function calcValues(test) {
    let newTest = {...test};
    const height = newTest?.height;
    newTest = {
        ...newTest,
        zWeight: zValue(newTest?.weight, "weight", height),
        zSitHeight: zValue(newTest?.sitHeight, "sitHeight", height),

        tricepsFold: calcParticularValue(newTest["tricepsFold"], "tricepsFold", height),
        subcapularFold: calcParticularValue(newTest["subcapularFold"], "subcapularFold", height),
        bicepsFold: calcParticularValue(newTest["bicepsFold"], "bicepsFold", height),
        iliacCrestFold: calcParticularValue(newTest["iliacCrestFold"], "iliacCrestFold", height),
        supraspinalFold: calcParticularValue(newTest["supraspinalFold"], "supraspinalFold", height),
        abdominalFold: calcParticularValue(newTest["abdominalFold"], "abdominalFold", height),
        thighFold: calcParticularValue(newTest["thighFold"], "thighFold", height),
        twinFold: calcParticularValue(newTest["twinFold"], "twinFold", height),
        
        relaxedArmPerimetral: calcParticularValue(newTest["relaxedArmPerimetral"], "relaxedArmPerimetral", height),
        contractedArmPerimetral: calcParticularValue(newTest["contractedArmPerimetral"], "contractedArmPerimetral", height),
        waistPerimetral: calcParticularValue(newTest["waistPerimetral"], "waistPerimetral", height),
        hipPerimetral: calcParticularValue(newTest["hipPerimetral"], "hipPerimetral", height),
        thighPerimetral: calcParticularValue(newTest["thighPerimetral"], "thighPerimetral", height),
        twinPerimetral: calcParticularValue(newTest["twinPerimetral"], "twinPerimetral", height),
    
        zHumerus: zValue(newTest?.humerusDiameter, "humerusDiameter", height),
        zBistyloid: zValue(newTest?.bistyloidDiameter, "bistyloidDiameter", height),
        zFemur: zValue(newTest?.femurDiameter, "femurDiameter", height),
    } //FALTA zFatMass que depende de Mg
    return newTest;
}

const pi = Math.PI;

export function calcCorrectedPerimetral(perimetral, fold) {
    const per = perimetral[0].total;
    const fol = fold[0].total;
    if (per && fol) return parseFloat(per - (pi * fol / 10)).toFixed(1);
    return null;
}
export function sumatory(test, list) {
    let suma = 0;
    for (let i = 0; i < list?.length; ++i) {
        const actE = list[i];
        if (test[actE][0]?.total) suma += test[actE][0]?.total;
    }
    return parseFloat(suma);
}
export function calcWaistHipIndex(waistPerimetral, hipPerimetral) {
    if (waistPerimetral && hipPerimetral) return parseFloat(waistPerimetral / hipPerimetral).toFixed(1);
    return null;
}

export const dcCalcOptions = [
    {_id: 1, name: "Whiters y cols (1987a)"},
    {_id: 2, name: "Durnin-Womersley (1974)"}
]

export function calcDC(newTest) {
    const option = newTest?.dcCalcId, gender = newTest?.gender;
    if (option && gender) {
        if (option === 1) {
            if (gender === "male") {
                const elements = [
                    "tricepsFold", "subcapularFold", "bicepsFold",
                    "supraspinalFold", "abdominalFold", "thighFold", "twinFold"
                ]
                const sum = sumatory(newTest, elements);
                return parseFloat(1.0988 - (0.0004*sum));
            }
            else {
                const elements = [
                    "tricepsFold", "subcapularFold",
                    "supraspinalFold", "twinFold"
                ]
                const sum = Math.log10(sumatory(newTest, elements));
                return parseFloat(1.17484 - (0.07229 * sum));
            }
        } else if (option === 2) {
            const elements = [
                "tricepsFold", "subcapularFold", "bicepsFold", "iliacCrestFold"
            ]
            const sum = Math.log10(sumatory(newTest, elements));
            if (gender === "male") return parseFloat(1.1765 - (0.0744*sum));
            else return parseFloat(1.1567 - (0.0717*sum));
        }
    }

    return 0;
}

export function calcGreix(dc) {
    if (dc) return parseFloat((495 / dc) - 450).toFixed(1);
    return null;
}

export function calcMG(weight, greix) {
    if (weight && greix) return parseFloat(weight * (greix / 100)).toFixed(1);
    return null;
}

export function calcMLG(weight, mg) {
    if (weight && mg) return parseFloat(weight - mg).toFixed(1);
    return null;
}

function calcEndomorphic(test) {
    const tr = test?.tricepsFold[0]?.total, sub = test?.subcapularFold[0]?.total;
    const sup = test?.supraspinalFold[0]?.total, h = test?.height;
    if (tr && sub && sup && h) {
        const sum = (170.18 / (tr + sub + sup)) * h;
        const sum2 = sum * sum;
        const sum3 = sum2 * sum;
        return parseFloat(-0.7182 + 0.1451*sum - 0.00068*sum2 + 0.0000014*sum3).toFixed(3);
    }
    return 0;
}

function calcMesomorphic(test) {
    const arm = test?.contractedArmPerimetral[0]?.total, tr = test?.tricepsFold[0]?.total;
    const tw = test?.twinPerimetral[0]?.total, twF = test?.twinFold[0]?.total;
    const dH = test?.humerusDiameter, dF = test?.femurDiameter, h = test?.height;
    if (arm && tw && dH && dF && h && tr && twF) {
        const pcsArm = arm - tr/10, pcsTw = tw - twF/10;
        return parseFloat(0.858*dH + 0.601*dF + 0.188*pcsArm + 
            0.161*pcsTw - h*0.131 + 4.5).toFixed(3);
    }
    return 0;
}

function calcEctomorphic(test) {
    const weight = test?.weight, height = test?.height;
    if (weight && height) {
        const hwr = height / (weight / (1/3));
        if (hwr >= 40.75) return parseFloat(0.732*hwr - 28.58).toFixed(3);
        else return parseFloat(0.463*hwr - 17.63).toFixed(3);
    }
    return 0;
}

export function calcSomatotip(test) {
    let newTest = {...test};

    newTest.endomorphic = calcEndomorphic(newTest);
    newTest.mesomorphic = calcMesomorphic(newTest);
    newTest.ectomorphic = calcEctomorphic(newTest);
    
    return newTest;
}

export function sumSpecificPlecs(test) {
    const elements = [
        "tricepsFold", "subcapularFold",
        "supraspinalFold", "abdominalFold", "thighFold", "twinFold"
    ]
    return parseFloat(sumatory(test, elements)).toFixed(1);
}

export function sumPlecs(test) {
    const elements = [
        "tricepsFold", "subcapularFold", "bicepsFold", "iliacCrestFold",
        "supraspinalFold", "abdominalFold", "thighFold", "twinFold"
    ]
    return parseFloat(sumatory(test, elements)).toFixed(1);
}

export function calcDCThings(test) {
    let newTest = {...test};

    newTest.dc = calcDC(newTest);
    newTest.greix = calcGreix(newTest?.dc);
    newTest.mG = calcMG(newTest?.weight, newTest?.greix);
    newTest.mLG = calcMLG(newTest?.weight, newTest?.mG);
    newTest.zFatMass = zValue(newTest?.mG, "fatMass", newTest?.height);

    return newTest;
}

export function calcSpecificValue(test, element) {
    return {
        ...test,
        [element]: calcParticularValue(test[element], element, test?.height)
    }
}

export function calcSpecificElement(test, element, number) {
    let newTest = {...test};
    
    if (element === "weight") {
        const weight = newTest?.weight;
        if (newTest?.height) newTest.imc = calcIMC(weight, newTest?.height);
        newTest.mG = calcMG(weight, newTest?.greix);
        newTest.mLG = calcMLG(weight, newTest?.mG);
        newTest.zFatMass = zValue(newTest?.mG, "fatMass", newTest?.height);
        newTest.zWeight = zValue(weight, "weight", newTest?.height);
        newTest = calcSomatotip(newTest);
    }
    else if (element === "height") {
        const height = newTest?.height, weight = newTest?.weight;
        if (weight) newTest.imc = calcIMC(weight, height);
        newTest.zFatMass = zValue(newTest?.mG, "fatMass", height);
        newTest = calcValues(newTest);
        newTest = calcSomatotip(newTest);
    }
    else if (element === "sitHeight") {
        newTest.zSitHeight = zValue(newTest?.sitHeight, "sitHeight", newTest?.height);
    }
    else if (element === "humerusDiameter") {
        newTest.zHumerus = zValue(newTest?.humerusDiameter, "humerusDiameter", newTest?.height);
        newTest.mesomorphic = calcMesomorphic(newTest);

    }
    else if (element === "bistyloidDiameter") {
        newTest.zBistyloid = zValue(newTest?.bistyloidDiameter, "bistyloidDiameter", newTest?.height);
    }
    else if (element === "femurDiameter") {
        newTest.zFemur = zValue(newTest?.femurDiameter, "femurDiameter", newTest?.height);
        newTest.mesomorphic = calcMesomorphic(newTest);
    }
    else if (number) {
        newTest = calcSpecificValue(newTest, element);
        newTest = calcDCThings(newTest);
        newTest = calcPerimetralAndFoldThings(newTest);
        newTest = calcSomatotip(newTest);
    }

    return newTest;
}

export function calcPerimetralAndFoldThings(test) {
    let newTest = {...test};

    newTest.correctedArm = calcCorrectedPerimetral(newTest?.relaxedArmPerimetral, newTest?.tricepsFold);
    newTest.correctedThigh = calcCorrectedPerimetral(newTest?.thighPerimetral, newTest?.thighFold);
    newTest.correctedTwin = calcCorrectedPerimetral(newTest?.twinPerimetral, newTest?.twinFold);
    newTest.sumSpecificPlecs = sumSpecificPlecs(newTest);
    newTest.sumPlecs = sumPlecs(newTest);
    newTest.waistHipIndex = calcWaistHipIndex(newTest?.waistPerimetral[0]?.total, newTest?.hipPerimetral[0]?.total);
    
    return newTest;
}

export function calcsIsakTest(test) {
    let newTest = {...test};

    newTest = calcValues(newTest);
    const weight = newTest?.weight, height = newTest?.height;
    if (weight && height) newTest.imc = calcIMC(weight, height);
    newTest = calcDCThings(newTest);
    newTest = calcPerimetralAndFoldThings(newTest);
    newTest = calcSomatotip(newTest);

    return newTest;
}