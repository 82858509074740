import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
	countProductsPatient,
	deleteProductsPatient,
	getProductsPatientById,
	postProductsPatient,
	updateProductsPatient,
} from "../../../../api/productsPatient";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSkeleton } from "../../../hooks/useSkeleton";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyProductPatient() {
	return {
		fullName: {},
		imageURL: null,
		active: true,
	};
}

export default function EditProductsPatientPage() {
	const [productPatient, setProductPatient] = useState(getEmptyProductPatient());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [productsLength, setProductsLenght] = useState(0);

	const productPatientId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!productPatientId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [productPatient])

	useEffect(() => {
		if (!productsLength)
			countProductsPatient()
				.then((res) => {
					setProductsLenght(res.data)
				})
				.catch((error) => {
					if (error.request.status != 404)
						alertError({
							error: error,
							customMessage: "Could not count"
						})
				})
	}, [productsLength])

	useEffect(() => {
		if (!productPatientId) {
			disableLoadingData();
			return;
		}
		getProductsPatientById(productPatientId)
			.then((res) => {
				if (res.status === 200) {
					setProductPatient(res.data);
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get product patient.",
				});
				history.push("/products-patient");
			});
	}, [productPatientId, disableLoadingData, history]);

	function saveProductPatient() {
		if (!productPatient?.fullName)
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (!productPatientId) {
			postProductsPatient({...productPatient, number: productsLength})
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Food family successfully created.",
						});
						history.push("/products-patient");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save product patient.",
					});
				});
		} else {
			updateProductsPatient(productPatientId, productPatient)
				.then((res) => {
					if (res.status === 200) {
						alertSuccess({
							title: "Saved!",
							customMessage: "Changes successfully saved.",
						});
						history.push("/products-patient");
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save changes.",
					});
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!productPatient[element]) productPatient[element] = {};
			let newText = productPatient[element];
			newText[lang] = event.target.value;
			setProductPatient({ ...productPatient, [element]: newText });
		} else
			setProductPatient({
				...productPatient,
				[element]: event.target.value,
			});
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(productPatient.fullName && productPatient.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
			</>
		);
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={productPatient?._id?'Food family: ' + (productPatient.fullName?productPatient.fullName.es:''):'New product patient'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/products-patient");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveProductPatient()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save product patient
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/products-patient")
							}}
						/>
						{productPatientId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete product patient
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this product patient?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteProductsPatient(productPatientId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Food family deleted successfully",
													});
													history.push("/products-patient");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete product patient.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<FormControlLabel
							control={
								<Checkbox
									checked={productPatient.active}
									onChange={() =>
										setProductPatient({
											...productPatient,
											active: !productPatient.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
				</Card>
			</>
		);
}
