import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postVisitType = async (visitType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "visitTypes");
		visitType.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/visit-type`, visitType);
};

export const getVisitTypes = () => {
	return authClient().get(`${API}/visit-type?getDisabled=true`);
};

export const getVisitTypeById = (id) => {
	return authClient().get(`${API}/visit-type/${id}`);
};

export const updateVisitType = async (id, visitType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "visitTypes");
		visitType.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/visit-type/${id}`, visitType);
};

export const changeStatusVisitType = async (id, active) => {
	return authClient().put(`${API}/visit-type/change-status/${id}`, {
		active,
	});
};

export const deleteVisitType = (id) => {
	return authClient().delete(`${API}/visit-type/${id}`);
};
