import { API, authClient } from "../index";

export const postUserProducts = async (userProducts) => {
	return authClient().post(`${API}/user-products`, userProducts);
};

export const getUserProducts = () => {
	return authClient().get(`${API}/user-products?getDisabled=true`);
};

export const getUserProductsById = (id) => {
	return authClient().get(`${API}/user-products/${id}`);
};

export const getUserProductsByUserId = (userId) => {
	return authClient().get(`${API}/user-products/user/${userId}`);
};

export const updateUserProducts = async (id, userProducts) => {
	return authClient().put(`${API}/user-products/${id}`, userProducts);
};

export const changeStatusUserProducts = async (id, active) => {
	return authClient().put(`${API}/user-products/change-status/${id}`, {
		active,
	});
};

export const deleteUserProducts = (id) => {
	return authClient().delete(`${API}/user-products/${id}`);
};
