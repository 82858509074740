import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';

import { useHistory, useParams } from 'react-router-dom';

import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
} from '../../../../_metronic/_partials/controls';

import {
	getSendingById,
	postSending,
	searchReceivers,
} from '../../../../api/sending';
import { getUsers } from '../../../../api/user';
import { alertError, alertSuccess } from '../../../../utils/logger';
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock';
import PreviewSendingDialog from '../../../components/dialogs/PreviewSendingDialog';
import { dateFormatter } from '../../../components/tables/table';
import { useSkeleton } from '../../../hooks/useSkeleton';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: () => null,
};

const NUTRITION_SEGMENTATION_OPTIONS = [
	{ segmentation: 'Without nutrition plan', code: 'NO_NUTRITION_PLAN' },
	{
		segmentation: 'With normal nutrition plan',
		code: 'NORMAL_NUTRITION_PLAN',
	},
	{
		segmentation: 'With personalized nutrition plan',
		code: 'PERSONALIZED_NUTRITION_PLAN',
	},
];

const FITNESS_SEGMENTATION_OPTIONS = [
	{ segmentation: 'Without fitness plan', code: 'NO_FITNESS_PLAN' },
	{ segmentation: 'With fitness plan', code: 'FITNESS_PLAN' },
];

function getEmptySending() {
	return {
		title: {},
		text: {},
		users: [],
		dateFrom: null,
		dateTo: null,
	};
}

export default function EditSendingsPage() {
	const [sending, setSending] = useState(getEmptySending());
	const sendingId = useParams().id;
	const history = useHistory();
	const [users, setUsers] = useState(null);
	const [isView, setIsView] = useState(false);

	const [loading, setLoading] = useState(false);
	const [previewDialog, openPreviewDialog] = useState(false);
	const [receivers, setReceivers] = useState([]);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
	const [changes, setChanges] = useState(-2);

	useEffect(() => {
		if (!sendingId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [sending])

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res.status === 200) {
					const filteredUsers = res.data.filter((item) =>
						item.role.includes('patient')
					);
					setUsers(
						filteredUsers.sort((a, b) => b.createdAt - a.createdAt)
					);
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get users.',
				});
				history.push('/sendings');
			});
		if (!sendingId) {
			disableLoadingData();
			return;
		}
		getSendingById(sendingId)
			.then((res) => {
				if (res.status === 200) {
					setSending({
						title: res.data.title,
						text: res.data.text,
						users: res.data.users?.map((item) => item._id) || [],
						receivers: res.data.receivers?.map((item) => item._id) || [],
						dateFrom: res.data.dateFrom,
						dateTo: res.data.dateTo,
					});
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: 'Could not get sending.',
				});
				history.push('/sendings');
			});
		setIsView(true);
	}, [sendingId, disableLoadingData, history]);

	async function saveSending() {
		if (!sendingId) {
			setLoading(true);
			postSending({ ...sending, receivers })
				.then((res) => {
					if (res.status === 201) {
						alertSuccess({
							title: 'Saved!',
							customMessage: 'Sending successfully created.',
						});
						history.push('/sendings');
					}
				})
				.catch((error) => {
					alertError({
						error: error,
						customMessage: 'Could not save sending.',
					});
					setLoading(false);
				});
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === ' ') return;
		if (lang) {
			if (!sending[element]) sending[element] = {};
			let newText = sending[element];
			newText[lang] = event.target.value;
			setSending({ ...sending, [element]: newText });
		} else setSending({ ...sending, [element]: event.target.value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`title`}
					label='Title'
					value={(sending.title && sending.title[lang]) || ''}
					onChange={handleChange('title', lang)}
					InputLabelProps={{ shrink: true }}
					disabled={isView}
					margin='normal'
					variant='outlined'
					required
				/>
				<br />
				<TextField
					id={`text`}
					label='Text'
					multiline
					value={(sending.text && sending.text[lang]) || ''}
					onChange={handleChange('text', lang)}
					InputLabelProps={{ shrink: true }}
					disabled={isView}
					margin='normal'
					variant='outlined'
				/>
			</>
		);
	};

	async function handlePreview() {
		if (
			!sending.title ||
			Object.keys(sending.title).length === 0 ||
			!sending.text ||
			Object.keys(sending.text).length === 0
		) {
			alertError({
				customMessage: 'Title and text are required.',
			});
			return;
		}
		const res = await searchReceivers(sending);
		const receivers = res.data.receivers.map((item) => {
			const user = users?.find((user) => user._id === item);
			return {
				_id: item,
				lang: user?.defaultLanguage,
				fullName: user?.name+' '+user?.surname,
			};
		});

		setReceivers(receivers);
		openPreviewDialog(true);
	}

	const getNonEmpty = (field) => {
		if (
			field.fullName &&
			typeof field.fullName.trim === 'function' &&
			field.fullName.trim() !== ''
		) {
			const formatedDate = new Date(field.createdAt).toLocaleDateString();
			return field.name + ' ' + field.surname + ' - ' + field.email + ' - ' + formatedDate;
		} else {
			return '----';
		}
	};

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
					<CardHeader
						title={!isView ? 'Edit sending' : `Sending #${sendingId}`}
					>
						<div className='mt-5'>
							<Button
								onClick={() => {
									if (changes > 0) setOpenConfirmDialog(1);
									else history.push("/sendings");
								}}
								variant='outlined'
								style={{ marginRight: '20px' }}>
								Back
							</Button>
							{!isView && (
								<Button
									onClick={() => handlePreview()}
									variant='outlined'
									color='primary'
									disabled={loading}
									style={{ marginRight: '20px' }}>
									Send content
								</Button>
							)}
						</div>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/sendings")
							}}
						/>
					</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={renderMultilanguageTabContent}
							required
						/>
						<br />
						<br />
						<hr />
						<h5 className='mt-4 mb-0'>User segmentation</h5>
						<br />
						<h6 className='mt-4 mb-0'>By user</h6>
						<br />
						<FormControl style={{ width: '100%' }}>
							<Autocomplete
								multiple
								id='users'
								filterSelectedOptions
								disablePortal
								disableCloseOnSelect
								options={users || []}
								getOptionLabel={(option) => getNonEmpty(option)}
								value={
									users?.filter((u) =>
										sending.users.includes(u._id)
									) || []
								}
								onChange={(event, newValue) => {
									const users = newValue.map((item) => item._id);
									setSending({ ...sending, users: users });
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										margin='normal'
										variant='outlined'
										InputLabelProps={{
											shrink: true,
										}}
										required
										placeholder='Select Users'
										label={'Users'}
									/>
								)}
							/>
							<FormHelperText>Select users</FormHelperText>
						</FormControl>
						<br />
						<br />
						
						<div className='row d-flex align-items-center'>
							<div className='col-md-6'>
								<h6 className='mt-4 mb-0'>
									By user creation (start date)
								</h6>
								<br />
								{isView ? (
									<p>{dateFormatter(sending.dateFrom, true)}</p>
								) : (
									<TextField
										id='dateFrom'
										label='Date from'
										type='date'
										value={
											sending.dateFrom &&
											sending.dateFrom.includes('T')
												? sending.dateFrom.split('T')[0]
												: sending.dateFrom
										}
										onChange={handleChange('dateFrom')}
										InputLabelProps={{ shrink: true }}
										//disabled={isView}
										margin='normal'
										variant='outlined'
									/>
								)}
							</div>
							<div className='col-md-6'>
								<h6 className='mt-4 mb-0'>
									By user creation (end date)
								</h6>
								<br />
								{isView ? (
									<p>{dateFormatter(sending.dateTo, true)}</p>
								) : (
									<TextField
										id='dateTo'
										label='Date to'
										type='date'
										value={
											sending.dateTo &&
											sending.dateTo.includes('T')
												? sending.dateTo.split('T')[0]
												: sending.dateTo
										}
										onChange={handleChange('dateTo')}
										InputLabelProps={{ shrink: true }}
										//disabled={isView}
										margin='normal'
										variant='outlined'
									/>
								)}
							</div>
						</div>						
					</CardBody>
					{!isView && (
						<CardFooter>
							<p className='mt-5'>
								<strong>Important!</strong> You must select the
								filters for the segmentation and the content will be
								sent directly to all users who match all the selected
								filters
							</p>
						</CardFooter>
					)}
					{isView && (
						<CardFooter>
							<h5 className='mt-0 mb-4'>Receivers</h5>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id='demo-simple-select-standard-label'>
									Users
								</InputLabel>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									value={sending.receivers}
									multiple
									disabled
									MenuProps={MenuProps}>
									{users?.map((option) => (
										<MenuItem
											key={option._id}
											value={option._id}>
											{option.fullName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</CardFooter>
					)}
				</Card>
				<PreviewSendingDialog
					open={previewDialog}
					setOpen={openPreviewDialog}
					receivers={receivers}
					onConfirm={() => saveSending()}
				/>
			</>
		);
}
