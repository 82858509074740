import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import { Autocomplete } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";
import { getFoodPlates } from "../../../../api/foodPlate";
import MyAutocomplete from "../../MyAutocomplete";
import { formatFloat } from "../../../../utils/helpers";
import { EditRecipeInfo } from "../../../../utils/recipeUtils";

function getEmptyMeal() {
	return {
		name: null,
		kcals: 0,
		proteins: 0,
		carbohydrates: 0,
		fats: 0,
		fiber: 0,
        grams: 0
	};
}

const EditBlockFoodDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, foodPlates, foods, isFood, main, typeStructure } = props;

	const [meal, setMeal] = useState(getEmptyMeal());
    const [completeMeal, setCompleteMeal] = useState(null);
	const [foodOptions, setFoodOptions] = useState([]);
	const [foodPlateOptions, setFoodPlateOptions] = useState([]);
	const [refresh, setRefresh] = useState(false);
	
    const [portionOptions, setPortionOptions] = useState([]);
	const [selectedPortion, setSelectedPortion] = useState({_id: -1});
    
    const [changes, setChanges] = useState(0);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (!open || !data) setMeal(getEmptyMeal());
		else {
            const completeMeal = isFood?
                foods.find((x) => x._id === data._id) :
                foodPlates.find((x) => x._id === data._id);
            setCompleteMeal(completeMeal);

            const meal = refreshCalcs(isFood? data?.grams : 100, data, completeMeal);
            setMeal(meal);
            
            if (isFood) {

                actualizePortionOptions(meal);
                if (meal?.selectedPortion >= 0) setSelectedPortion({_id: meal.selectedPortion});
            }
        }
	}, [data, open]);

    useEffect(() => {
        if (foodPlates?.length > 0 && foodPlateOptions?.length === 0) 
            refreshRecipesOptions(foodPlates);
        
        if (foods?.length > 0 && foodOptions?.length === 0) {
            let foodOptions = [];
            for (let i = 0; i < foods?.length; ++i) 
                foodOptions.push({
                    id: foods[i]._id,
                    fullName: foods[i].fullName.es
                })
            setFoodOptions(foodOptions);
        }
        
    }, [foodPlates, foods])

    useEffect(() => {
        if (isFood) {
            const food = meal;
            if (selectedPortion?._id !== -1 && food?.portions?.length > 0) {
                const portionComplete = food?.portions?.find((x) => x._id === selectedPortion?._id);
                const grams = portionComplete?.grams * food?.portionQuant;
                setMeal(refreshCalcs(grams, meal, completeMeal));
            }
        }
    }, [selectedPortion, meal?.portionQuant])

    useEffect(() => {
        if (meal?.grams && completeMeal) refreshCalcs(meal.grams, meal, completeMeal)
    }, [meal?.grams])

    function getRecipes() {
        getFoodPlates()
            .then((res) => {
                if (res.status === 200) {
                    refreshRecipesOptions(res.data);
                }
            })
            .catch((error) => {
                alertError({
                    error: error,
                    customMessage: "Could not get food plates"
                });
            });
    }

    function actualizePortionOptions(food) {
		let data = [];

		if (food?.portions?.length > 0) {
			const portions = food.portions;
			data.push({
				_id: -1,
				name: "No portion"
			});
			for (let i = 0; i < portions.length; ++i) {
				let elem = {};
				const act = portions[i];
				
				elem._id = i;
				elem.name = act?.name;
				
				data.push(elem);
			}
		}

		setPortionOptions(data);
	}

    function refreshRecipesOptions(foodPlates) {
        let foodPlateOptions = [];
            for (let i = 0; i < foodPlates?.length; ++i) 
                foodPlateOptions.push({
                    id: foodPlates[i]._id,
                    fullName: foodPlates[i].description.es
                })
        setFoodPlateOptions(foodPlateOptions);
    }

    function refreshCalcs(grams, meal, completeMeal) {
        const cm = completeMeal;
        const kcals = isFood? parseFloat(cm?.kcals) : cm?.totalKcals;
        const proteins = isFood? parseFloat(cm?.proteins) : cm?.totalProteins;
        const carbohydrates = isFood? parseFloat(cm?.carbohydrates) : cm?.totalCarbohydrates;
        const fats = isFood? parseFloat(cm?.fats) : cm?.totalFats;
        const fiber = isFood? parseFloat(cm?.fiber) : cm?.totalFiber;
        const newMeal = {
            ...meal,
            grams: grams,
            kcals: kcals * parseFloat(grams) / 100 || 0,
            proteins: proteins * parseFloat(grams) / 100 || 0,
            carbohydrates: carbohydrates * parseFloat(grams) / 100 || 0,
            fats: fats * parseFloat(grams) / 100 || 0,
            fiber: fiber * parseFloat(grams) / 100 || 0,
        };

        return newMeal;
    }

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!meal[element]) meal[element] = {};
			let newText = meal[element];
			newText[lang] = event.target.value;
			setMeal({ ...meal, [element]: newText });
		} else if (element === 'grams') setMeal(refreshCalcs(event.target.value, meal, completeMeal));
        else setMeal({ ...meal, [element]: event.target.value });
        setRefresh(true);
	};

    function close() {
        setOpen(false);
        setMeal(getEmptyMeal());
        setPortionOptions([]);
        setSelectedPortion({_id: -1})
    }

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
                <MyAutocomplete
                    options={ isFood? 
                        foodOptions : 
                        foodPlateOptions }
                    getOptionLabel={(option) =>
                        option.fullName
                    }
                    value={ isFood? 
                        foodOptions.find(option => option.id === meal?._id) :
                        foodPlateOptions.find(option => option.id === meal?._id) ||
                        null }
                    onChange={(event, selected) => {
                        const completeMeal = (isFood?
                            foods.find((x) => x._id === selected?.id) :
                            foodPlates.find((x) => x._id === selected?.id))
                        setCompleteMeal(completeMeal);
                        
                        let newMeal = {};
                        if (isFood) {
                            newMeal = {...meal, portions: completeMeal?.portions || []};
                            actualizePortionOptions(newMeal);
                            newMeal.fullName = selected?.fullName;
                            newMeal._id = selected?.id;
                            newMeal = refreshCalcs(newMeal?.grams, newMeal, completeMeal);
                            newMeal.selectedPortion = -1;
                        }
                        else {
                            const antMeal = meal;
                            newMeal = {
                                ...completeMeal, 
                                typeStructure: typeStructure,
                                structure: antMeal?.structure,
                                _id: antMeal?._id,
                                extraMeal: antMeal?.extraMeal,
                                number: antMeal?.number || null
                            };
                        }
                        setMeal({...newMeal})
                        
                        setRefresh(true)
                    }}
                    placeholder="Select type of meal"
                    label={"Type of meal"}
                />
				
				<br />
			{isFood ? (
                <>
                    <Row>
                        <Col sm={8}>
                            <MyAutocomplete
                                options={ portionOptions }
                                getOptionLabel={(option) => option.name}
                                value={
                                    portionOptions?.find(
                                        (x) => x._id === selectedPortion?._id
                                    ) || null
                                }
                                onChange={(event, selected) => {
                                    setSelectedPortion(selected)
                                    setRefresh(true);
                                }}
                                placeholder="Select portion"
                                label={"Portion"}
                            />
                        </Col>
                        <Col sm={4}>
                            <TextField
                                id={"portionQuant"}
                                label={"Quantity"}
                                value={meal?.portionQuant || 0}
                                onChange={handleChange('portionQuant', false)}
                                InputLabelProps={{shrink: true}}
                                margin="normal"
                                variant="standard"
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id={`grams`}
                                label="grams"
                                InputProps={{
                                    inputProps: {
                                        min: 0
                                    }
                                }}
							    inputProps={{ readOnly: (selectedPortion?._id >= 0)? true : false}}
                                onChange={handleChange('grams', false)}
                                value={meal?.grams || 0}
                                margin="normal"
                                className={(selectedPortion?._id >= 0)? 'readonly' : ''}
                                variant="standard"
                                type="number"
                            />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col sm={4}>
                            <TextField
                                id={`kcals`}
                                label="KCAL"
                                inputProps={{ readOnly: true }}
                                value={formatFloat(meal?.kcals) || 0}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col sm={2}>
                            <TextField
                                id={`carbohydrates`}
                                label="CHO"
                                inputProps={{ readOnly: true }}
                                value={formatFloat(meal?.carbohydrates) || 0}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col sm={2}>
                            <TextField
                                id={`proteins`}
                                label="PRO"
                                inputProps={{ readOnly: true }}
                                value={formatFloat(meal?.proteins) || 0}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col sm={2}>
                            <TextField
                                id={`fats`}
                                label="FAT"
                                inputProps={{ readOnly: true }}
                                value={formatFloat(meal?.fats) || 0}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                        <Col sm={2}>
                            <TextField
                                id={`fiber`}
                                label="FIBER"
                                inputProps={{ readOnly: true }}
                                value={formatFloat(meal?.fiber) || 0}
                                margin="normal"
                                variant="standard"
                                type="number"
                                className='readonly'
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {!refresh && 
                    <EditRecipeInfo
                    foodPlate={meal}
                    setFoodPlate={setMeal}
                    allFoods={foods}
                    foodPlateId={meal?._id}
                    changes={changes} setChanges={setChanges}
                    isStructure={true}
                    />
                    }
                </>
            )}

            {main && (
                <Row>
                    <Col>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={meal?.extraMeal || false}
                                    onChange={() =>{
                                        setMeal({...meal, extraMeal: !meal?.extraMeal})
                                    }}
                                    name="checkActive"
                                />
                            }
                            label="Extra meal"
                        />
                    </Col>
                </Row>
            )}
            <br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						close()
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if ((isFood && !meal?.fullName) || (!isFood && !meal?.description?.es)) {
							alertError({
								error: null,
								customMessage:
									"The meal is required",
							});
						} else {
							onSave({...meal, selectedPortion: selectedPortion?._id});
                            close()
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default EditBlockFoodDialog;