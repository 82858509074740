import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { alertError } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../MultilanguageTabBlock";
import { Autocomplete } from "@material-ui/lab";
import { Col, Row } from "react-bootstrap";
import MyAutocomplete from "../../MyAutocomplete";

function getEmptyActivity() {
	return {
		fullName: null,
		kcals: 0,
        lvl: "1",
        time: 0
	};
}

const EditBlockActivityDialog = (props) => {
	const { title, data, open, setOpen, onSave, readOnly, activities } = props;

	const [activity, setActivity] = useState(getEmptyActivity());
    const [completeActivity, setCompleteActivity] = useState(null);
    const [levelOptions, setLevelOptions] = useState([]);
	const [activityOptions, setActivityOptions] = useState([]);
    const [firstActivity, setFirstActivity] = useState(null);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
	}, [refresh]);

	useEffect(() => {
		if (!open || !data) setActivity(getEmptyActivity());
		else {
            const completeActivity = activities.find((x) => x._id === data._id);
            setCompleteActivity(completeActivity);
            const activity = refreshCalcs(0, data, completeActivity);
            setFirstActivity(activity);
            setActivity(activity);
        }
        
        if (levelOptions?.length === 0) initializeLevelOptions();
	}, [data, open]);

    useEffect(() => {
        if (activities?.length > 0 && activityOptions?.length === 0) {
            let activityOptions = [];
            for (let i = 0; i < activities?.length; ++i) 
                activityOptions.push({
                    id: activities[i]._id,
                    fullName: activities[i].fullName.es
                })
            setActivityOptions(activityOptions);
        }
        
    }, [activities]);

    function initializeLevelOptions() {
        let data = [];

        for (let i = 1; i < 6; ++i) {
            let elem = {};
            elem._id = i;
            elem.lvl = i.toString();
            data.push(elem);
        }

        setLevelOptions(data);
    }

    function refreshCalcs(value, activity, completeActivity, field) {
        
        const ca = completeActivity;
        let kcals = 0;
        if (ca !== null) 
            kcals = parseFloat(ca["kcal".concat((field === 'lvl')? value : activity?.lvl)]);
        const time = parseFloat((field === 'time')? value : activity?.time);
        const newActivity = {
            ...activity,
            [field]: value,
            kcals: kcals * time
        };

        return newActivity;
    }

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		//Siempre se modifican las "kcals", ya que todos los campos afectan
        setActivity(refreshCalcs(event.target.value, activity, completeActivity, element))
        setRefresh(true);
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
			</>
		);
	};

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="table-dialog"
		>
			<DialogTitle id="table-dialog">{title}</DialogTitle>
			<DialogContent>
                <MyAutocomplete
                    options={ activityOptions }
                    getOptionLabel={(option) =>
                        option.fullName
                    }
                    value={ activityOptions.find(option => option.id === activity?._id) || null }
                    onChange={(event, selected) => {
                        const completeActivity = (activities.find((x) => x._id === selected?.id))
                        setCompleteActivity(completeActivity);
                        let newActivity = {...activity};
                        newActivity._id = selected?.id;
                        setActivity(refreshCalcs(selected?.fullName, newActivity, completeActivity, 'fullName'))
                        setRefresh(true)
                    }}
                    placeholder="Select activity"
                    label={"Type of activity"}
                />
				
				<br />
				<MultilanguageTabBlock
					multilanguageTabContent={renderMultilanguageTabContent}
				/>
            
            <Row>
                <Col>
                    <MyAutocomplete
                        options={ levelOptions }
                        getOptionLabel={(option) =>
                            option.lvl
                        }
                        value={ levelOptions?.find(option => option?.lvl === activity?.lvl) || null }
                        onChange={(event, selected) => {
                            let newActivity = {...activity};
                            setActivity(refreshCalcs(selected?.lvl, newActivity, completeActivity, 'lvl'))
                            setRefresh(true)
                        }}
                        placeholder="Select level"
                        label={"Level"}
                    />
                    
                </Col>
                <Col>
                    <TextField
                        id={`time`}
                        label="Time (in minutes)"
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        onChange={handleChange('time', false)}
                        value={activity?.time || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <TextField
                        id={`kcals`}
                        label="KCAL"
                        inputProps={{ readOnly: true }}
                        value={activity?.kcals || 0}
                        margin="normal"
                        variant="standard"
                        type="number"
                        className='readonly'
                    />
                </Col>
            </Row>

				<br />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						setOpen(false);
						setActivity(getEmptyActivity());
                        setCompleteActivity(null);
                        setFirstActivity(null);
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (!activity?.fullName) {
							alertError({
								error: null,
								customMessage:
									"The activity is required",
							});
						} else {
							setOpen(false);
							onSave(activity, firstActivity);
                            setFirstActivity(null)
                            setCompleteActivity(null)
							setActivity(getEmptyActivity());
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default EditBlockActivityDialog;