import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
} from '@material-ui/core';
import CreatableSelect from 'react-select/creatable';

const selectStyle = {
	multiValue: (base) => {
		return { ...base, paddingRight: 6 };
	},
	multiValueRemove: () => {
		return { display: 'none' };
	},
};

const PreviewSendingDialog = (props) => {
	const { open, setOpen, receivers, onConfirm } = props;

	const getDataSelect = (elements) => {
		const data = [];
		for (const element of elements) {
			let elem = {};
			elem.label = element.fullName;
			elem.value = element._id;
			elem.isFixed = true;
			data.push(elem);
		}
		return data;
	};

	const data = getDataSelect(receivers);

	return (
		<Dialog disableBackdropClick={true}
			fullWidth={true}
			maxWidth='md'
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby='new-session-dialog'>
			<DialogTitle id='new-session-dialog'>
				SEGMENTED SENDING PREVIEW
			</DialogTitle>
			<DialogContent>
				<h5 className='mt-0 mb-4'>USERS THAT WILL RECEIVE THE NOTIFICATION</h5>
				<FormControl style={{ width: '100%' }} className='ml-2'>
					<FormHelperText>Users</FormHelperText>
					<CreatableSelect
						className='mb-3'
						isMulti
						isDisabled
						value={data}
						styles={selectStyle}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}>
					<span style={{ color: 'white' }}>SEND</span>
				</Button>
				<Button
					variant='outlined'
					color='secondary'
					onClick={() => setOpen(false)}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default PreviewSendingDialog;
