import React, { useState } from 'react'
import { AppBar, Tabs, Tab } from '@material-ui/core'

function CalendarTabLock({ calendarTabContent, onClick, tab, setTab }) {

	const handleChangeTabs = async (_, newValue) => {
		const tab = await onClick(newValue);
		if (tab) setTab(newValue)
	}

	return (
		<>
			<AppBar position="static" color="default" key="appbar">
				<Tabs
					value={tab}
					onChange={handleChangeTabs}
					variant="scrollable"
					scrollButtons="auto"
					key="tabs"
				>
					<Tab
						key="TAB1"
						label="VISIT" />
					<Tab
						key="TAB2"
						label="TASK" />
				</Tabs>
			</AppBar>
			<div key={`TABCONTENT${tab}`}>
				{calendarTabContent[tab]}
			</div>
		</>
	)
}

export default CalendarTabLock;
