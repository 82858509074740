
export function calcAge(value) {
    const today = new Date();
    const birth = new Date(value);

    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    const dayDifference = today.getDate() - birth.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}

//Height in cms
export function calcIMC(weight, height) {
    const newHeight = parseFloat(height) / 100;
    const calc = parseFloat(weight) / (newHeight*newHeight);
    return calc.toFixed(1);
}

export function calcIdealWeight(imc, height) {
    const newHeight = parseFloat(height) / 100;
    if (parseFloat(imc) < 20.5) return (20.5 * newHeight).toFixed(1);
    if (parseFloat(imc) > 24) return (24 * newHeight).toFixed(1);
    return 0;
}

export function calcAdjustedWeight(objectiveWeight, actualWeight) {
    return (objectiveWeight - (actualWeight - objectiveWeight) * 0.25).toFixed(1);
}

export function calcFatMass(actualWeight, fatPercentage) {
    return (actualWeight * (fatPercentage / 100)).toFixed(1);
}

export function calcFatFreeMass(actualWeight, fatMass) {
    return (actualWeight - fatMass).toFixed(1);
}

export function calcPAL(work, leisure) {
    if (work > 0) return ((((((Number(work) + Number(leisure))/10) + 1) - 1.2) * 0.5) + 1.2).toFixed(2)
    else return (((Number(leisure)/2)/10) + 1.2 - 0.05).toFixed(2)
}

export function calcTMB(age, gender, height, selectedWeight, fatFreeMass, tmb) {
    if (tmb === "0") {
        if (gender === 'male') {
            if (!isNaN(selectedWeight) && !isNaN(height)) return ((10*Number(selectedWeight))+(6.25*Number(height))-(5*age)+5).toFixed(0);
        } else {
            if (!isNaN(selectedWeight) && !isNaN(height)) return ((10*Number(selectedWeight))+(6.25*Number(height))-(5*age)-161).toFixed(0);
        }
    }
    if (tmb === "1") {
        var genderCheck = 0
        if (gender === 'male') genderCheck = 1
        if (!isNaN(selectedWeight) && !isNaN(height)) return ((11.936*Number(selectedWeight))+(587.728*Number(height)/100)-(8.129*Number(age))+(191.027*genderCheck)+29.279).toFixed(0)
    }
    if (tmb === "2") {
        if (!isNaN(fatFreeMass)) return (370 + (21.6 * Number(fatFreeMass))).toFixed(0)
    }
    return 0;
}

export function calcTMBKcalkgh(tmb, selectedWeight) {
    return ((Number(tmb) / Number(selectedWeight)) / 24).toFixed(1);
}

export function calcTMBKcalh(tmb, selectedWeight) {
    return (Number(tmb) / Number(selectedWeight)).toFixed(1);
}

export function calcTMBMlkgmin(tmb, selectedWeight) {
    return ((((parseFloat(tmb) /1440) / 5) / parseFloat(selectedWeight)) * 1000).toFixed(1);
}

export function calcGER(tmb, pal) {
    return (tmb * pal).toFixed(0);
}

export function calcGERKcalkgh(ger, actualWeight) {
    return ((Number(ger) / Number(actualWeight)) / 24).toFixed(1);
}

export function calcGERKcalh(ger) {
    return (Number(ger) / 24).toFixed(1);
}

export function calcGERMlkgmin(ger, actualWeight) {
    return ((((Number(ger) / 1440) / 5) / actualWeight) * 1000).toFixed(1);
}