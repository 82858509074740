import React from 'react';
import { Col, Row } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Editor from '../../../components/editor/Editor';
import OnlyText from '../../../components/textFields/OnlyText';

export default function TaskInformation(props) {
    const { task, setTask } = props;
    
    const handleChange = (element) => (event) => {
		if (event.target.value === " ") return;
			setTask({
				...task,
				[element]: event.target.value,
			});
	};

    const handleChangeEditor = (element, new_body) => {
        setTask({
            ...task,
            [element]: new_body
        })
    }

    return (
        <>
            <Row>
                <Col sm={4}>
                    <TextField
                        id={`taskDate`}
                        label="Date"
                        value={task?.date}
                        onChange={handleChange('date')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="normal"
                        variant="standard"
                        type='datetime-local'
                        required
                    />
                </Col>
                <Col sm={8}>
                    <OnlyText title={"Title"} element={"title"} handleChange={handleChange} data={task}/>
                </Col>
            </Row>
            <Editor
                disabled={false}
                body={task?.description}
                setBody={(new_body) => {
                    if (new_body !== '') handleChangeEditor("description", new_body);
                }}
                className="max-height"
                placeholder={"Enter description..."}
                name="Description"
            />
        </>
    )
}