import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

export const postCompetitionType = async (competitionType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "competitionTypes");
		competitionType.imageURL = response.data.fileURL;
	}
	return authClient().post(`${API}/competition-type`, competitionType);
};

export const getCompetitionTypes = () => {
	return authClient().get(`${API}/competition-type?getDisabled=true`);
};

export const getCompetitionTypeById = (id) => {
	return authClient().get(`${API}/competition-type/${id}`);
};

export const updateCompetitionType = async (id, competitionType, image) => {
	if (image) {
		const response = await uploadSingleFile(image, "competitionTypes");
		competitionType.imageURL = response.data.fileURL;
	}
	return authClient().put(`${API}/competition-type/${id}`, competitionType);
};

export const changeStatusCompetitionType = async (id, active) => {
	return authClient().put(`${API}/competition-type/change-status/${id}`, {
		active,
	});
};

export const deleteCompetitionType = (id) => {
	return authClient().delete(`${API}/competition-type/${id}`);
};
