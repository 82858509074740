import {
	Button,
	Checkbox,
	createMuiTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	MuiThemeProvider,
	TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { alertError } from "../../../../utils/logger";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { getVisitTypes } from "../../../../api/visitType";
import { getUserById } from "../../../../api/user";
import VisitsInformation from "../../../modules/ContentManager/visits/VisitsInformation";
import { getLastVisit } from "../../../../api/visit";
import ConfirmDialog from "./../ConfirmDialog";
import CalendarTabLock from "../../../modules/ContentManager/calendar/CalendarTabBlock";
import EditVisitDialog from "../EditVisitDialog";
import { getEmptyVisit } from "../../../../utils/visitUtils";
import TaskInformation from "../../../modules/ContentManager/tasks/TaskInformation";

const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

const CalendarDialogs = (props) => {
    const { 
		setOpen, open, onSave, onDelete, visits, userId, users, date, typeEvent, setTypeEvent,
		selectedEvent, setSelectedEvent, visitTypes, predefPatient
	 } = props;

	const [changes, setChanges] = useState(-2);
	const [tabValue, setTabValue] = useState(0);
	const [tabAux, setTabAux] = useState(0);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(-1);

	useEffect(() => {
		if (open) {
			if (!typeEvent) setSelectedEvent(getEmptyVisit(date, null));
			else if (typeEvent === 1) {
				let newData = selectedEvent;
				if (newData?.visitDate) {
					let visitDate = new Date(newData.visitDate);
					newData.visitDate = new Date(visitDate.getTime() - visitDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
				}
            	setSelectedEvent(newData);
			} else if (typeEvent === 2) {
				let newData = selectedEvent;
				if (newData?.date) {
					let taskDate = new Date(newData.date);
					newData.date = new Date(taskDate.getTime() - taskDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
				}
            	setSelectedEvent(newData);
			}
		}
		
	}, [open])

	useEffect(() => {
		if (changes <= 0) setChanges(changes+1);
	}, [selectedEvent])

	function getEmptyTask() {
		let formatDate;
		if (date) {
			const newDate = new Date(date);
			const now = new Date(); 
			newDate.setHours(now.getHours() + 6);
			newDate.setMinutes(now.getMinutes());
			newDate.setSeconds(now.getSeconds());
			newDate.setMilliseconds(0);
			newDate.setDate(newDate.getDate() - 1);
			formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 600000).toISOString().split('.')[0];
		}
		else {    
			const newDate = new Date();
			formatDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toISOString().split('.')[0];
		}
		return {
			date: formatDate,
			title: null,
			description: ""
		}
	}

	const renderCalendarTabContent = [
		<>
			<br />
			<VisitsInformation 
			visit={selectedEvent}
			setVisit={setSelectedEvent}
			visitTypes={visitTypes}
			user={null}
			users={users? users : null}
			predefPatient={false}
			/>
		</>,
		<>
			<br />
			<TaskInformation
			task={selectedEvent}
			setTask={setSelectedEvent}
			/>
			<br />
		</>
	];

	function onClose() {
		setTypeEvent(0);
		setTabValue(0);
		setOpen(false);
	}

	function visitsComprobation() {
		if (!selectedEvent?.user) {
			alertError({
				error: null,
				customMessage: "User is required"
			})
			return false;
		}
		else if (!selectedEvent?.visitType) {
			alertError({
				error: null,
				customMessage: "Type visit is required"
			})
			return false;
		}
		else if (!selectedEvent?.visitDate) {
			alertError({
				error: null,
				customMessage: "Date is required"
			})
			return false;
		}

		return true;
	}

	function tasksComprobation() {
		if (!selectedEvent?.title) {
			alertError({
				error: null,
				customMessage: "Title is required"
			})
			return false;
		} else if (!selectedEvent?.date) {
			alertError({
				error: null,
				customMessage: "Date is required"
			})
			return false;
		}

		return true;
	}

    return (
        <Dialog disableBackdropClick={true}
			fullWidth={true}
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			aria-labelledby="event-dialog"
			PaperProps={{
				className: 'customDialog'
			}}
		>
			<DialogTitle id="event-dialog">{"Event"}</DialogTitle>
			<ConfirmDialog 
                title={
                    "Are you sure you want to navigate? You will lose all youre changes"
                }
                open={openConfirmDialog === 1}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                    if (tabAux === 0) {
						setSelectedEvent(getEmptyVisit(date))
					} else if (tabAux === 1) {
						setSelectedEvent(getEmptyTask())
					}
					setChanges(-1);
					setTabValue(tabAux);
                }}
            />
			<ConfirmDialog 
                title={
                    "Are you sure you want to delete?"
                }
                open={openConfirmDialog === 2}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
					onDelete(selectedEvent, typeEvent);
					onClose();
                }}
            />
			<DialogContent>
				{typeEvent? (typeEvent === 1)? (
					<VisitsInformation 
					visit={selectedEvent}
					setVisit={setSelectedEvent}
					visitTypes={visitTypes}
					user={userId}
					users={users? users : null}
					predefPatient={predefPatient? predefPatient : false}
					/>
				) : (
					<>
						<TaskInformation 
						setTask={setSelectedEvent}
						task={selectedEvent}
						/>
						<br />
					</>
				) : (
					<CalendarTabLock 
					onClick={async (newValue) => {
						if (changes > 0) {
							setOpenConfirmDialog(1);
							setTabAux(newValue);
							return false;
						}
						else {
							if (newValue === 0) setSelectedEvent(getEmptyVisit(date));
							else if (newValue === 1) setSelectedEvent(getEmptyTask());
							setChanges(-1);
							setTabValue(newValue);
						}
						return true;
					}}
					tab={tabValue}
					setTab={setTabValue}
					calendarTabContent={
						renderCalendarTabContent
					}
					/>
				)}
				
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					style={{ marginRight: "15px" }}
					onClick={() => {
						onClose()
					}}
				>
					Close
				</Button>
				<Button
					variant="outlined"
					color="primary"
                    style={{ marginRight: "15px" }}
					onClick={() => {
						const type = typeEvent? typeEvent : tabValue+1;
						let saveAble = true;
						if (type === 1) saveAble = visitsComprobation();
						else if (type === 2) saveAble = tasksComprobation();
						if (saveAble) {
							onSave(selectedEvent, type);
							onClose();
						}
					}}
				>
					Save
				</Button>
                {typeEvent && (
                    <MuiThemeProvider theme={theme}>
                        <Button
                        onClick={() => setOpenConfirmDialog(2)}
                        variant="outlined"
                        color="secondary"
                        >
                            Delete
                        </Button>
                    </MuiThemeProvider>
                )}
                
			</DialogActions>
		</Dialog>
    );
};

export default CalendarDialogs;