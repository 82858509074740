import { API, authClient } from "../index";

export const getNotes = () => {
	return authClient().get(`${API}/note?getDisabled=true`);
};

export const getNoteById = (id) => {
	return authClient().get(`${API}/note/${id}`);
};

export const deleteNote = (id) => {
  console.log('id:',id);
	return authClient().delete(`${API}/note/${id}`);
};

export const postNote = async (note) => {
	return authClient().post(`${API}/note`, note);
};

export const updateNote = async (id, note) => {
	return authClient().put(`${API}/note/${id}`, note);
};

export const changeStatusNote = async (id, active) => {
	return authClient().put(`${API}/note/change-status/${id}`, {
		active,
	});
};
