import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	Tooltip,
	createMuiTheme,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Delete, Edit, Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
	deleteStructure,
	getStructureById,
	postStructure,
	updateStructure,
} from "../../../../api/structure";
import { checkIsEmpty, formatFloat } from "../../../../utils/helpers";
import { alertError, alertSuccess } from "../../../../utils/logger";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import EditStructureBlockDialog from "../../../components/dialogs/structures/EditStructureBlockDialog";
import Table, {
	buttonsStyle,
} from "../../../components/tables/table";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { Row, Col } from 'react-bootstrap';
import Editor from "../../../components/editor/Editor";
import { blockCalc, deleteBlockRecipesFromBD, styleFormat } from "../../../../utils/structuresCalcs";
import { postFoodPlateStructure, updateFoodPlateStructure } from "../../../../api/foodPlateStructure";

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyStructure() {
	return {
		fullName: {},
		description: {},
		kcal: 0,
		carbohydrates: 0,
		protein: 0,
		fats: 0,
		fiber: 0,
		blocks: [],
		imageURL: null,
		active: true,
	};
}

export default function EditStructuresPage() {
	const [structure, setStructure] = useState(getEmptyStructure());
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);

	const [openEditBlockDialog, setOpenEditBlockDialog] = useState(false);
	const [openViewBlockDialog, setOpenViewBlockDialog] = useState(false);
	const [isMeal, setIsMeal] = useState(false);
	const [blocks, setBlocks] = useState([]);
	const [selectedBlock, setSelectedBlock] = useState(null);

	const [refresh, setRefresh] = useState(false);
	const [changes, setChanges] = useState(-2);

	const [selectedDeleteBlock, setSelectedDeleteBlock] = useState(-1);

	const structureId = useParams().id;
	const history = useHistory();
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

	const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

	function typeFormatter(cell) {
		const block = blocks.find((x) => cell === x.number);
		return (block?.isIntake? (!block?.intake.isSnack || block?.intake?.isSnack === '0')? "Main" : "Snack" : "Exercise");
	}
	
	function blockMove(block, index, newIndex) {
		let blocks = [
			...structure.blocks
		]
		const blockUp = blocks.find(
			(x) => x.number === newIndex
		)
		
		blocks[newIndex] = {
			...block,
			number: newIndex
		};
		blocks[index] = {
			...blockUp,
			number: index
		}

		reCalcNutritionalValues({
			...structure,
			blocks: blocks
		});
		setBlocks(blocks);
		setRefresh(true);
	}

	async function openData(block) {
		setSelectedBlock(block);
	}

	useEffect(() => {
		if (selectedBlock) setOpenEditBlockDialog(true);
	}, [selectedBlock])

	useEffect(() => {
		if (!openEditBlockDialog) setSelectedBlock(null);
	}, [openEditBlockDialog])

	function blocksButtonFormatter(cell) {
		const index = blocks.findIndex((x) => x.number === cell);
		const block = blocks[index];

		return (
			<>
				<Tooltip title="Edit">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							openData(block);
							// setOpenEditBlockDialog(true);
							// setSelectedBlock(block);
							setIsMeal(block.isIntake);
						}}
					>
						<Edit />
					</Button>
				</Tooltip>
				<Tooltip title="Move up">
					<Button
						size="small"
						style={buttonsStyle}
						disabled={index === 0}
						onClick={() => blockMove(block, index, index-1)}
					>
						<ArrowUpward />
					</Button>
				</Tooltip>
				<Tooltip title="Move down">
					<Button
						size="small"
						disabled={
							index >= structure.blocks?.length - 1
						}
						style={buttonsStyle}
						onClick={() => blockMove(block, index, index+1)}
					>
						<ArrowDownward />
					</Button>
				</Tooltip>
				<Tooltip title="Delete">
					<Button
						style={buttonsStyle}
						size="small"
						onClick={() => {
							setOpenConfirmDialog(3)
							setSelectedDeleteBlock(index);
						}}
					>
						<Delete />
					</Button>
				</Tooltip>
			</>
		)
	}

	useEffect(() => {
		if (!structureId) {
			disableLoadingData();
			return;
		}
		getStructureById(structureId)
			.then((res) => {
				if (res.status === 200) {
					let structureData = res.data;

					if (structureData?.block?.length > 0) {
						for (
							let i = 0;
							i < structureData?.block?.length;
							++i
						) {
							structureData.block[i].id = i;
						}
					}

					reCalcNutritionalValues({...res.data})
					setBlocks(res.data.blocks)
					disableLoadingData();
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not get structure.",
				});
				history.push("/structures");
			});
	}, [structureId, disableLoadingData, history]);

	useEffect(() => {
		setRefresh(false);
	}, [refresh])

	useEffect(() => {
		if (!structureId) setChanges(changes+2);
		else setChanges(changes+1);
	}, [structure])

	async function deleteBlock(index) {
		const res = await deleteBlockRecipesFromBD(structure, index);
		if (res) {
			let newBlocks = [...blocks];
			newBlocks.splice(index, 1);

			for (let i = index; i < newBlocks?.length; ++i) newBlocks[i].number = newBlocks[i].number - 1;

			const newStructure = await reCalcNutritionalValues({
				...structure,
				blocks: [...newBlocks]
			});
			setBlocks([...newBlocks]);
			setSelectedDeleteBlock(-1);
			if (newStructure?._id) {
				const res = await updateSt(newStructure);
				if (res) {
					const data = await getStructureById(newStructure._id)
						.catch((error) => {
							alertError({
								error: error,
								customMessage: "Could not get user structures information, please recharge the page."
							})
						});
					const saveStructure = data?.data || newStructure;
					setChanges(-1);
					reCalcNutritionalValues(saveStructure)
					setBlocks(saveStructure?.blocks);
				}
			}
			setRefresh(true);
		}
	}

	async function saveRecipe(recipe) {
		if (recipe?._id) {
			const res = await updateFoodPlateStructure(recipe?._id, recipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not update recipe."
					})
					return null;
				})
			if (res) return recipe?._id;
		}
		else {
			delete recipe._id
			const res = await postFoodPlateStructure(recipe)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save recipe."
					})
					return null;
				})
				
			if (res) return res.data._id;
		}

		return null;
	}

	async function updateSt(structure) {
		for (let i = 0; i < structure?.blocks?.length; ++i) {
			let actB = {...structure?.blocks[i]};
			if (actB?.isIntake) {
				if (actB?.intake?.recipes?.length > 0) {
					let recipes = actB?.intake?.recipes;
					for (let j = 0; j < recipes?.length; ++j) {
						let actR = {...actB?.intake?.recipes[j].recipe};
						actR.structure = structure?._id;
						const res = await saveRecipe(actR);
						if (!res) return;
						actR = res;
						recipes[j].recipe = actR;
					}
					actB = {
						...actB, 
						intake: {
							...actB?.intake, 
							recipes: recipes 
						}
					}
					structure.blocks[i] = actB;
				}
			}
		}

		const res = await updateStructure(structure?._id, structure, null)
			.then((res) => {
				if (res.status === 200) {
					alertSuccess({
						title: "Saved!",
						customMessage: "Changes successfully saved.",
					});
					setChanges(0);
					return true;
				}
			})
			.catch((error) => {
				alertError({
					error: error,
					customMessage: "Could not save changes.",
				});
				return false;
			});
		if (res) return true;
		return false;
	}

	async function saveStructure() {

		async function postStruct(structure) {
			const res = await postStructure(structure, null)
				.catch((error) => {
					alertError({
						error: error,
						customMessage: "Could not save structure.",
					});
					return null;
				});
			return res;
		}

		let saveStructure = structure;
		
		if (saveStructure?.block?.length > 0) {
			saveStructure.block.forEach((block) => {
				delete block.id;
			});
		}

		if (!structureId) {
			const auxStructure = {...structure}
			delete auxStructure.blocks;
			const res = await postStruct(auxStructure);
			if (!res) return;
			saveStructure._id = res.data._id;
		}

		if (checkIsEmpty(structure.fullName))
			return alertError({
				error: null,
				customMessage:
					"The name is required in at least one of the languages.",
			});
		if (saveStructure?._id) {
			const res = await updateSt(saveStructure);
			if (res) history.push("/structures");;
		}
	}

	const handleChange = (element, lang) => (event) => {
		if (event.target.value === " ") return;
		if (lang) {
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = event.target.value;
			setStructure({ ...structure, [element]: newText });
		} else
			setStructure({
				...structure,
				[element]: event.target.value,
			});
	};

	const handleChangeEditor = (element, lang, value) => {
		if (lang) {
			if (value === " ") return;
			if (!structure[element]) structure[element] = {};
			let newText = structure[element];
			newText[lang] = value;
			setStructure({ ...structure, [element]: newText });
		} else setStructure({ ...structure, [element]: value });
	};

	const renderMultilanguageTabContent = (lang) => {
		return (
			<>
				<br />
				<TextField
					id={`fullName`}
					label="Full name"
					value={
						(structure.fullName && structure.fullName[lang]) || ""
					}
					onChange={handleChange("fullName", lang)}
					InputLabelProps={{
						shrink: true,
					}}
					margin="normal"
					variant="standard"
					required
				/>
				<br />
				<Editor
					body={(structure.description && structure.description[lang]) || ""}
					setBody={(new_body) =>
						handleChangeEditor("description", lang, new_body)
					}
					className="max-height"
					lang={lang}
					placeholder={"Enter structure description here..."}
					name="Description"
				/>
			</>
		);
	};

	function titleFormatter(cell) {
		const block = structure?.blocks.find((x) => cell === x.number);
		if (block?.intake?.optional) return block?.name + " (Optional)";
		else return block?.name;
	}

	const blockColumns = [
		{
			dataField: "number",
			text: "title",
			style: styleFormatter,
			formatter: titleFormatter
		},
		{
			dataField: "number",
			text: "type",
			style: styleFormatter,
			formatter: typeFormatter
		},
		{
			dataField: "kcals",
			text: "kcal",
			headerAlign: 'center',
			align: 'center',
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "carbohydrates",
			text: "cho",
			headerAlign: 'center',
			align: 'center',
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "proteins",
			text: "prot",
			headerAlign: 'center',
			style: styleFormatter,
			align: 'center',
			formatter: formatFloat
		},
		{
			dataField: "fats",
			text: "fat",
			headerAlign: 'center',
			align: 'center',
			style: styleFormatter,
			formatter: formatFloat
		},
		{
			dataField: "fiber",
			text: "fiber",
			headerAlign: 'center',
			align: 'center',
			style: styleFormatter,
			formatter: formatFloat,
		},
		{
			dataField: "number",
			text: "",
			formatter: blocksButtonFormatter,
			style: styleFormatter,
			align: 'right',
			headerStyle: { width: '200px'}
		}
	];

	function styleFormatter(cell, row) {
		const block = blocks?.find((x) => x.number === row.number);
		return styleFormat(block);
	}

	function getDataBlocks(blocks) {

		function sortByNumber(a, b) {
			if (a.number < b.number) return -1;
			if (a.number > b.number) return 1;
			return 0;
		}

		let data = [];

		for (let i = 0; i < blocks.length; ++i) {
			let elem = {};
			const block = blocks[i];

			elem._id = block._id;
			elem.number = block.number;
			elem.kcals = block?.kcals || 0;
			elem.carbohydrates = block?.carbohydrates || 0;
			elem.fats = block?.fats || 0;
			elem.fiber = block?.fiber || 0;
			elem.proteins = block?.proteins || 0;

			data.push(elem);
		}

		data.sort(sortByNumber);

		return data;
	}

	async function reCalcNutritionalValues(structure) {
		let blocks = [...structure?.blocks] || [];
		let kcals = 0;
		let carbohydrates = 0;
		let protein = 0;
		let fats = 0;
		let fiber = 0;

		for (let i = 0; i < blocks.length; ++i) {
			let block = blocks[i];
			const calcs = await blockCalc(block);

			block.kcals = calcs?.kcals;
			block.carbohydrates = calcs?.carbohydrates;
			block.proteins = calcs?.proteins;
			block.fats = calcs?.fats;
			block.fiber = calcs?.fiber;

			if (block?.isIntake) {
				if (block?.intake?.optional) {
					let kcalsSum = calcs?.kcals;
					let carbohydratesSum = calcs?.carbohydrates;
					let proteinSum = calcs?.proteins;
					let fatsSum = calcs?.fats;
					let fiberSum = calcs?.fiber;
					let counter = 1;
					
					for (let j = i+1; j < blocks?.length; ++j) {
						const actBlock = blocks[j];
						if (actBlock?.intake?.optional) {
							const calcs = await blockCalc(actBlock);
							kcalsSum += calcs?.kcals;
							carbohydratesSum += calcs?.carbohydrates;
							proteinSum += calcs?.proteins;
							fatsSum += calcs?.fats;
							fiberSum += calcs?.fiber;
							counter++;
						} else break;
					}

					kcals += kcalsSum/counter;
					carbohydrates += carbohydratesSum/counter;
					protein += proteinSum/counter;
					fats += fatsSum/counter;
					fiber += fiber/counter;
					i += counter-1;
				} else {
					kcals += calcs?.kcals;
					carbohydrates += calcs?.carbohydrates;
					protein += calcs?.proteins;
					fats += calcs?.fats;
					fiber += calcs?.fiber;
				}
			} else {
				kcals = kcals - calcs?.kcals;
			}
		}

		const newStructure = {
			...structure,
			kcal: kcals,
			carbohydrates: carbohydrates,
			protein: protein,
			fats: fats,
			fiber: fiber
		};
		setStructure(newStructure);
		setRefresh(true);
		return {...newStructure}; 
	}

	async function actualizeStructure(block) {
		const index = structure?.blocks?.findIndex((x) => x.number === selectedBlock?.number)
		let newBlocks = [...structure?.blocks];
		newBlocks[index] = block;
		const newStructure = {
			...structure,
			blocks: newBlocks
		}

		const res = await updateSt(newStructure);
		if (res) {setStructure({...newStructure}); setBlocks(newStructure?.blocks); setRefresh(true);}
		return res;
	}

	if (isLoadingData) return <ContentSkeleton />;
	else
		return (
			<>
				<Card>
				<CardHeader title={structure?._id?'Structure: ' + (structure.fullName?structure.fullName.es:''):'New structure'}>
					<div className="mt-5">
						<Button
							onClick={() => {
								if (changes > 0) setOpenConfirmDialog(1);
								else history.push("/structures");
							}}
							variant="outlined"
							style={{ marginRight: "20px" }}
						>
							Back
						</Button>
						<Button
							onClick={() => saveStructure()}
							variant="outlined"
							color="primary"
							style={{ marginRight: "20px" }}
						>
							Save structure
						</Button>
						<ConfirmDialog
							title={
								"Are you sure you want to go back? You will lose all your changes"
							}
							open={openConfirmDialog === 1}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								history.push("/structures")
							}}
						/>
						<ConfirmDialog
							title={
								"Are you sure you want to delete the block? You will lose all your block information"
							}
							open={openConfirmDialog === 3}
							setOpen={setOpenConfirmDialog}
							onConfirm={() => {
								deleteBlock(selectedDeleteBlock)
							}}
						/>
						{structureId && user?.role.includes("admin") && (
							<>
								<MuiThemeProvider theme={theme}>
									<Button
										onClick={() => setOpenConfirmDialog(2)}
										variant="outlined"
										color="secondary"
										style={{ marginRight: "20px" }}
									>
										Delete structure
									</Button>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											marginLeft: "auto",
										}}
									></div>
								</MuiThemeProvider>

								<ConfirmDialog
									title={
										"Are you sure you want to delete this structure?"
									}
									open={openConfirmDialog === 2}
									setOpen={setOpenConfirmDialog}
									onConfirm={() => {
										deleteStructure(structureId)
											.then((res) => {
												if (
													res.status === 204 ||
													res.status === 200
												) {
													alertSuccess({
														title: "Deleted!",
														customMessage:
															"Structure deleted successfully",
													});
													history.push("/structures");
												}
											})
											.catch((error) => {
												alertError({
													error: error,
													customMessage:
														"Could not delete structure.",
												});
											});
									}}
								/>
							</>
						)}
					</div>
				</CardHeader>
					<CardBody>
						<MultilanguageTabBlock
							multilanguageTabContent={
								renderMultilanguageTabContent
							}
						/>
						<br />
						<Row>
							<Col>
								<TextField
									id={`kcal`}
									label="KCAL"
									value={formatFloat(structure.kcal)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`carbohydrates`}
									label="CHO"
									value={formatFloat(structure.carbohydrates)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`protein`}
									label="PRO"
									value={formatFloat(structure.protein)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fats`}
									label="FAT"
									value={formatFloat(structure.fats)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
							<Col>
								<TextField
									id={`fatskg`}
									label="FIBER"
									value={formatFloat(structure?.fiber)}
									InputLabelProps={{
									shrink: true
									}}
									margin="normal"
									variant="standard"
									required
									type="number"
									className='readonly'
								/>
							</Col>
						</Row>
						
						<FormControlLabel
							control={
								<Checkbox
									checked={structure.active}
									onChange={() =>
										setStructure({
											...structure,
											active: !structure.active,
										})
									}
									name="checkActive"
								/>
							}
							label="Active"
						/>
					</CardBody>
					<CardHeader title="Block">
						<CardHeaderToolbar>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(false);
								}}
							>
								Add exercise
							</button>
							<button
								type="button"
								className="btn btn-primary"
								style={{marginLeft: 10}}
								onClick={() => {
									setOpenEditBlockDialog(true);
									setSelectedBlock(null);
									setIsMeal(true)
								}}
							>
								Add meal
							</button>
						</CardHeaderToolbar>
					</CardHeader>
					<CardBody>
						{!refresh &&
							blocks?.length > 0 && (
								<Table
									columns={blockColumns}
									data={getDataBlocks(blocks)}
								/>
							)}
					</CardBody>
					{ openEditBlockDialog ? (
						<>
							<EditStructureBlockDialog
								open={openEditBlockDialog || openViewBlockDialog}
								setOpen={
									openViewBlockDialog
										? setOpenViewBlockDialog
										: setOpenEditBlockDialog
								}
								data={selectedBlock}
								readOnly={openViewBlockDialog}
								meal={isMeal}
								typeStructure={"structure"}
								structureId={structureId}
								actualizeStructure={actualizeStructure}

								onSave={(block, isMeal) => {
									let newBlocks = (blocks?.length > 0)? [...blocks] : [];

									const index = newBlocks.findIndex(
										(x) => x.number === block.number
									);
									
									if (index !== -1) {
										newBlocks[index] = {
											...block,
										};
									} else {
										newBlocks.push({
											...block,
											number: newBlocks.length
										});
									}

									reCalcNutritionalValues({
										...structure,
										blocks: newBlocks
									});
									setBlocks(newBlocks);
								}}
							/>
						</>
					): <></>}
					
				</Card>
			</>
		);
}
