import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from "react-redux";
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	Checkbox,
	FormControlLabel,
	MuiThemeProvider,
	TextField,
	createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteNote, getNoteById, updateNote, postNote } from '../../../../api/note'
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import { useSkeleton } from '../../../hooks/useSkeleton'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { alertError, alertSuccess } from '../../../../utils/logger'
import Editor from '../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: "#F64E60",
		},
	},
});

function getEmptyNote() {
  return {
    title: {},
    content: {},
    active: true
  }
}

export default function EditNotesPage() {
  const [note, setNote] = useState(getEmptyNote())
	const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
  const noteId = useParams().id
  const history = useHistory()
	const user = useSelector(
		(store) => store.authentication?.user,
		shallowEqual
	);

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  const [changes, setChanges] = useState(-2);

  useEffect(() => {
    if (!noteId) setChanges(changes+2);
    else setChanges(changes+1);
  }, [note])

  useEffect(() => {
    if (!noteId) {
      disableLoadingData()
      return
    }
    getNoteById(noteId).then(res => {
      if (res.status === 200) {
        setNote(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get note.' })
      history.push('/notes')
    })
  }, [noteId, disableLoadingData, history])

  function saveNote() {
    if (!noteId) {
      postNote(note).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Note successfully created.' })
          history.push('/notes')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save note.' })
      })
    } else {
      updateNote(noteId, note).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/notes')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!note[element]) note[element] = {}
      let newText = note[element]
      newText[lang] = event.target.value
      setNote({ ...note, [element]: newText })
    } else setNote({ ...note, [element]: event.target.value })
  }

  const handleChangeEditor = (element, lang, value) => {
    if (lang) {
      if (value === ' ') return
      if (!note[element]) note[element] = {}
      let newText = note[element]
      newText[lang] = value
      setNote({ ...note, [element]: newText })
    } else setNote({ ...note, [element]: value })
  }


  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`title`}
					label="Title"
          value={(note.title && note.title[lang]) || ''}
          onChange={handleChange('title', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="standard"
          required
        />
        <Editor
          body={(note.content && note.content[lang]) || ''}
          setBody={new_body => handleChangeEditor('content', lang, new_body)}
          className='max-height'
          lang={lang}
          placeholder={'Enter note content here...'}
          name="Content"
        />
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
      <CardHeader title={note?._id?'Food note: ' + (note.title?note.title.es:''):'New note'}>
        <div className='mt-5'>
          <Button
            onClick={() => {
              if (changes > 0) setOpenConfirmDialog(1);
              else history.push("/notes");
            }}
            variant="outlined"
            style={{ marginRight: '20px' }}>
            Back
          </Button>
          <Button
            onClick={() => saveNote()}
            variant="outlined"
            color="primary"
            style={{ marginRight: '20px' }}>
            Save note
          </Button>
          <ConfirmDialog
            title={
              "Are you sure you want to go back? You will lose all your changes"
            }
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              history.push("/notes")
            }}
          />
              {noteId && user?.role.includes("admin") && (
                <>
                  <MuiThemeProvider theme={theme}>
                    <Button
                      onClick={() => setOpenConfirmDialog(2)}
                      variant="outlined"
                      color="secondary"
                      style={{ marginRight: "20px" }}
                    >
                      Delete note
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "auto",
                      }}
                    ></div>
                  </MuiThemeProvider>

                  <ConfirmDialog
                    title={
                      "Are you sure you want to delete this note?"
                    }
                    open={openConfirmDialog === 2}
                    setOpen={setOpenConfirmDialog}
                    onConfirm={() => {
                      deleteNote(noteId)
                        .then((res) => {
                          if (
                            res.status === 204 ||
                            res.status === 200
                          ) {
                            alertSuccess({
                              title: "Deleted!",
                              customMessage:
                                "Note deleted successfully",
                            });
                            history.push("/notes");
                          }
                        })
                        .catch((error) => {
                          alertError({
                            error: error,
                            customMessage:
                              "Could not delete note.",
                          });
                        });
                    }}
                  />
                </>
              )}
        </div>
      </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                checked={note.active}
                onChange={() =>
                  setNote({
                    ...note,
                    active: !note.active,
                  })
                }
                name="checkActive"
              />
            }
            label="Active"
          />
        </CardBody>
      </Card>
    </>
  );
}
