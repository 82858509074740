import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
import { useHistory } from 'react-router-dom';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { countAdmins } from '../../api/user'
import { countPatients } from '../../api/user'
import { countCompetitions } from '../../api/competition'
import { countFoodPlates } from '../../api/foodPlate'

export function DashboardPage() {
	const [data, setData] = useState([]);
    const history = useHistory()

	useEffect(() => {
		async function fetchData() {
			try {
				const admins = await countAdmins()
				const patients = await countPatients()
				const competitions = await countCompetitions()
				const recipes = await countFoodPlates()

				setData({
					numAdmins: admins.data,
					numPatients: patients.data,
					numCompetitions: competitions.data,
					numRecipes: recipes.data
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'Could not get statistics.'})
			}
		}
		fetchData()
	}, []);

	return (
		<>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/admins')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><VerifiedUserIcon/>  Administrators</h5>
							<div className='contentDash'>{data.numAdmins}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/patients')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><AssignmentIndIcon/>  Patients</h5>
							<div className='contentDash'>{data.numPatients}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/competitions')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><EmojiEventsIcon/>  Competitions</h5>
							<div className='contentDash'>{data.numCompetitions}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/food-plates')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><RestaurantIcon/>  Recipes</h5>
							<div className='contentDash'>{data.numCompetitions}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
