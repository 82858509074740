import React from 'react';
import { TextField } from "@material-ui/core";

//Si hay un editor, specific debe ser = false
//Es igual que onlyNumberRead pero sin el formato de los decimales
const OnlyNumberReadDec = React.memo(({title, element, data, specific}) => {
    if (!data) return (<></>)
    return (
        <>
            <TextField
                id={`${element}`}
                label={title}
                value={parseFloat(data[element] || 0)}
                InputLabelProps={{
                shrink: true
                }}
                inputProps={{readOnly: true}}
                margin="normal"
                variant="standard"
                className='readonly'
                type='number'
                required
            />
        </>
    )
}, (prevProps, nextProps) => {
    if (!prevProps?.data || !prevProps?.data) return false;
    if (prevProps?.specific === undefined || prevProps?.specific) return prevProps.data[prevProps.element] === nextProps.data[nextProps.element];
    return prevProps.data === nextProps.data;
});

export default OnlyNumberReadDec;