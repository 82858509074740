import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { Button, Col, Row } from "react-bootstrap";
import { alertError } from "../../../utils/logger";
import { getUserProductsByUserId } from "../../../api/userProducts";
import { useSkeleton } from "../../hooks/useSkeleton";
import { getProductsPatient } from "../../../api/productsPatient";
import { TextField } from "@material-ui/core";

const ProductsPatientTable = React.memo((props) => {
    const { patientId, userProducts, setUserProducts, productsPatient, setProductsPatient } = props;

    const [userProductsMap, setUserProductsMap] = useState({});

    function getEmptyUserProducts() {
        return {
            userId: patientId? patientId : null,
            products: [],
            active: true
        }
    }

    const {
		isLoading: isLoadingData,
		disableLoading: disableLoadingData,
		ContentSkeleton,
	} = useSkeleton();

    useEffect(() => {
        if (productsPatient?.length === 0) {
            getProductsPatient()
                .then((res) => {
                    if (res.status === 200) {
                        setProductsPatient(res.data)
                    }
                })
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not get productsPatient"
                    })
                });
        }
        if (patientId) {
            if (!userProducts?.userId) {
                getUserProductsByUserId(patientId)
                    .then((res) => {
                        if (res.status === 200) {
                            setUserProducts(res.data)
                            disableLoadingData();
                        }
                    })
                    .catch((error) => {
                        console.log(error.request.response)
                        setUserProducts(getEmptyUserProducts());
                    });
            }
        } else {
            setUserProducts(getEmptyUserProducts());
        }
        
    }, [disableLoadingData])

    useEffect(() => {
        if (userProducts?.products?.length > 0) handleUserProducts();
    }, [userProducts?.products])

    function handleUserProducts() {
        let newUserProductsMap = {...userProductsMap};
        userProducts.products.forEach((element) => {
            newUserProductsMap[element.productId.fullName.es] = element; 
        });
        
        setUserProductsMap(newUserProductsMap);
    }
    
    const handleProductChange = (event, product, productPatient, isType) => {
        const value = event.target.value;

        const newUserProducts = [...userProducts?.products] || [];
        const productIndex = newUserProducts.findIndex((x) => x.productId.fullName.es === productPatient.fullName.es);

        if (!product) {
            newUserProducts.push({
                productId: {
                    _id: productPatient._id,
                    fullName: productPatient.fullName
                },
                type: isType ? value : "",
                frequency: isType ? "" : value
            });
        } else {
            const prod = newUserProducts[productIndex];
            newUserProducts[productIndex] = {
                ...prod,
                type: isType ? value : prod.type,
                frequency: isType ? prod.frequency : value
            };
        }

        setUserProducts((prev) => ({
            ...prev,
            products: newUserProducts
        }));
    };

    return (
        <>
            <Row>
                <Col xs={2}><h5>Product</h5></Col>
                <Col xs={5}><h5>Type</h5></Col>
                <Col xs={5}><h5>Freqüency</h5></Col>
            </Row>

            {productsPatient.map((product, index) => {
                const userProduct = userProductsMap[product.fullName.es];
                return (
                    <Row style={{marginTop: '2px'}} key={index}>
                        <Col xs={2} style={{marginTop: '10px'}}>{product.fullName.es}</Col>
                        <Col xs={5}>
                        <TextField
                            style={{width: '100%', height: '50px'}}
                            label={""}
                            value={userProduct?.type}
                            onChange={(event) => handleProductChange(event, userProduct, product, true)}
                            InputLabelProps={{ shrink: true }}
                            margin="none"
                            variant="standard"
                            type="text"
                            required
                        />
                        </Col>
                        <Col xs={5}>
                        <TextField
                            style={{width: '100%', height: '50px'}}
                            label={""}
                            value={userProduct?.frequency}
                            onChange={(event) => handleProductChange(event, userProduct, product, false)}
                            InputLabelProps={{ shrink: true }}
                            margin="none"
                            variant="standard"
                            type="text"
                            required
                        />
                        </Col>
                    </Row>
                )
            })}
        </>
    )
});

export default ProductsPatientTable;