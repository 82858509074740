import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteUser, getUserById, postUser, sendVerifyUserMail, updateUser, verifyUserMail } from '../../../../api/user'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import PatientTabBlock from "../patients/PatientTabBlock";
import EditWorkingDaysDialog from "../../../components/dialogs/EditWorkingDaysDialog";
import EditPatientsCompetitions from './competition/EditPatientsCompetitions';
import { updateUserCompetitions } from '../../../../api/userCompetitions';
import EditPatientDiets from './EditPatientDiets'
import EditPatientVisits from './EditPatientVisits';
import EditPatientPhysiology from './EditPatientPhysiology';
import EditPatientInfo from './EditPatientInfo';
import { postUserProducts, updateUserProducts } from '../../../../api/userProducts';
import Editor from '../../../components/editor/Editor';
import { calcAdjustedWeight, calcAge, calcFatFreeMass, calcFatMass, calcGER, calcGERKcalh, calcGERKcalkgh, calcGERMlkgmin, calcIdealWeight, calcIMC, calcPAL, calcTMB, calcTMBKcalh, calcTMBKcalkgh, calcTMBMlkgmin } from '../../../../utils/patientCalcs';
import EditPatientDocuments from './documents/EditPatientDocuments';
import EditPatientStructures from './structures/EditPatientStructures';
import EditPatientMeasures from './measures/EditPatientMeasures'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyPatient() {
  return {
    fullName: '',
    email: '',
    role: 'patient',
    password: '',
    repeatPassword: '',
    active: true,
    verified: false,
    generalDescription: '',
    gender: '',
    work: '',
    leisure: '',
    imc: 0,
    selectedWeight: 0,
    idealWeight: 0,
    imcIdeal: 0,
    objectiveWeight: 0,
    imcObjective: 0,
    adjustedWeight: 0,
    adjustedImc: 0,
    pal: 1.15,
    formulaTMB: 0,
    workingDays: []
  }
}

export default function EditPatientsPage() {
  const [patient, setPatient] = useState(getEmptyPatient());
  const [patientId, setPatientId] = useState(useParams().id);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(0);
  const [newPassword, setNewPassword] = useState({ password: null, repeatPassword: null })
	const [changePassword, setChangePassword] = useState(false)
	const [openEditWorkingDaysDialog, setOpenEditWorkingDaysDialog] = useState(false);
	const [selectedWorkingDays, setSelectedWorkingDays] = useState(null);
  const [patientToken, setPatientToken] = useState(null);
  
  const [userProducts, setUserProducts] = useState(null);

  const [userCompetitions, setUserCompetitions] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const [visits, setVisits] = useState([]);

  const [userStructures, setUserStructures] = useState([]);

  const [changes, setChanges] = useState(-4);
  
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!patientId) {
      disableLoadingData()
      return
    }
    getUserById(patientId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        if (user.birthdate) {
          user.birthdate = new Date(user.birthdate).toISOString().split('T')[0];
        }
   
        const newUser = calcAllInfo(user);
        setPatient(newUser)
        setPatientToken(newUser?.verificationToken);
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get patient.' })
      history.push('/patients')
    })
  }, [disableLoadingData, history])

  useEffect(() => {
    if (!patientId) setChanges(changes+2);
    else setChanges(changes+1);
    // console.log(changes)
  }, [patient]);

  useEffect(() => {
    if(userProducts?._id) setChanges(changes+1);
    else if (userProducts) setChanges(changes+2);
  }, [userProducts])

  function calcBasicInformation(user) {
    let newUser = user;
    if (newUser?.birthdate) newUser.age = calcAge(newUser.birthdate);
    if (!isNaN(newUser?.height)) {
      if (!isNaN(newUser?.actualWeight)) {
        newUser.imc = calcIMC(newUser.actualWeight, newUser.height);
        newUser.idealWeight = calcIdealWeight(newUser.imc, newUser.height);
        newUser.idealImc = calcIMC(newUser.idealWeight, newUser.height);
      }
      if (!isNaN(newUser?.selectedWeight)) newUser.selectedImc = calcIMC(newUser.selectedWeight, newUser.height);
      if (!isNaN(newUser?.objectiveWeight)) newUser.imcObjective = calcIMC(newUser.objectiveWeight, newUser.height);
    }

    if(!isNaN(newUser.objectiveWeight) && !isNaN(newUser.actualWeight)) {
      newUser.adjustedWeight = calcAdjustedWeight(newUser.objectiveWeight, newUser.actualWeight);
      if (!isNaN(newUser?.height)) newUser.adjustedImc = calcIMC(newUser.adjustedWeight, newUser.height);
    }

    if (!isNaN(newUser?.actualWeight) && !isNaN(newUser?.fatPercentage)) {
      newUser.fatKilograms = calcFatMass(newUser.actualWeight, newUser.fatPercentage);
      newUser.fatFreeMass = calcFatFreeMass(newUser.actualWeight, newUser.fatKilograms);
    }

    return newUser;
  }

  function calcTmbInformation(user) {
    let newUser = user;
    if (!isNaN(newUser.formulaTMB)) {
      newUser.tmb = calcTMB(newUser.age, newUser.gender, 
        newUser.height, newUser.selectedWeight, 
        newUser.fatFreeMass, newUser.formulaTMB);
      if (!isNaN(newUser.tmb) && !isNaN(newUser.selectedWeight)) {
        newUser.tmbkcalkgh = calcTMBKcalkgh(newUser.tmb, newUser.selectedWeight);
        newUser.tmbmlkgmin = calcTMBMlkgmin(newUser.tmb, newUser.selectedWeight);
        newUser.tmbkcalh = calcTMBKcalh(newUser.tmb, newUser.selectedWeight);
      }
    }

    return newUser;
  }

  function calcPalInformation(user) {
    let newUser = user;
    if (!newUser.pal && !isNaN(newUser?.work, newUser?.leisure)) newUser.pal = calcPAL(newUser.work, newUser.leisure);
    
    if (!isNaN(newUser?.pal) && !isNaN(newUser?.tmb)) {
      newUser.ger = calcGER(newUser.tmb, newUser.pal)
      newUser.gerkcalh = calcGERKcalh(newUser.ger);
      if (!isNaN(newUser.actualWeight)) {
        newUser.gerkcalkgh = calcGERKcalkgh(newUser.ger, newUser.actualWeight);
        newUser.germlkgmin = calcGERMlkgmin(newUser.ger, newUser.actualWeight);
      }
    }
    
    return newUser;
  }

  function calcAllInfo(user) {
    let newUser = user;
    newUser = calcBasicInformation(user);
    newUser = calcTmbInformation(user);
    newUser = calcPalInformation(user);
    return newUser;
  }

  async function initializeUserProducts(id) {
    const newUserProducts = {
      ...userProducts,
      userId: id
    }
    
    const res = await postUserProducts(newUserProducts)
          .catch((error) => {
            alertError({
              error: error,
              customMessage: "Could not save userProducts"
            })
            return false;
          });
    
    setUserProducts({
      ...newUserProducts,
      _id: res.data._id
    });

    return true;
  }

  async function savePatientColumns(exit) {
    let savePatient = patient
    if (patientId && changePassword) {
        if (!newPassword.password || !newPassword.repeatPassword) {
          alertError({ error: null, customMessage: 'Please enter the password.' })
          return false
        }
        if (newPassword.password !== newPassword.repeatPassword) {
          alertError({ error: null, customMessage: 'Passwords do not match.' })
          return false
        }
        savePatient = {...savePatient, password: newPassword.password }
    }
      if (!patientId) {
        const res = await postUser(savePatient).catch(error => {
          alertError({error: error, customMessage: 'Could not save patient.'})
        });
        if (res?.status === 201) {
          setPatientId(res.data.id);
          setPatientToken(res.data.token);
          if (userProducts?.products?.length > 0) await initializeUserProducts(res.data.id);
          if (exit) {
            alertSuccess({ title: 'Saved!', customMessage: 'Patient successfully created.' })
            history.push('/patients')
          }
          return true;
        }
      } else {
        const res = await updateUser(patientId, savePatient).catch(error => {
          alertError({error: error, customMessage: 'Could not save changes.'})
        });
        if (res?.status === 200) {
          if (userProducts?.products?.length > 0 && (!userProducts?.userId || !userProducts?._id)) await initializeUserProducts(patientId);
          if (exit) {
            alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
            history.push('/patients')
          }
          return true;
        }
    }
    return false;
  }

  async function savePatientProducts(exit) {
    const res = await updateUserProducts(userProducts?._id, userProducts)
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not save userProducts"
        })
        return false;
      });
    
    if (res?.status === 200) {
      if (exit) {
        alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
        history.push('/patients')
      }
      return true;
    }
    return false;
  }

  async function savePatient(exit) {
    let res = true;
    if (userProducts?.products?.length > 0 && userProducts?.userId && userProducts?._id) {
      res = await savePatientColumns(false, false);
      if (!res) return false;
      res = await savePatientProducts(exit);
    }
    else res = await savePatientColumns(exit, true);
    if (!res) return false;
    alertSuccess({ title: 'Saved!', customMessage: 'Patient succesfully saved.' })
    return true;
  }

  const handleChange = (element) => (event) => {
    const value = event.target.value;

    // Actualizar el valor del elemento correspondiente
    setPatient((prevPatient) => {
      let updatedPatient = { ...prevPatient, [element]: value }
      updatedPatient = calcBasicInformation(updatedPatient);
      if (element === "actualWeight" || element === 'selectedWeight') {
        updatedPatient = calcTmbInformation(updatedPatient);
        updatedPatient = calcPalInformation(updatedPatient);
      } else if (element === 'pal') updatedPatient = calcPalInformation(updatedPatient);

      return updatedPatient
    });
  }

  const handleChangeEditor = (element, value) => {
    setPatient({ ...patient, [element]: value })
  }

  const handleChangeSelector = (element) => (event, selected) => {
    const value = event.target.value;
    
    setPatient((prevPatient) => {
      let updatedPatient = { ...prevPatient, [element]: selected?._id }

      if (element === 'formulaTMB'){
        updatedPatient = calcTmbInformation(updatedPatient);
      }

      updatedPatient = calcPalInformation(updatedPatient);

      return updatedPatient
    })
  }
  
  const handleSaveWorkingDays = (workingDays) => {
    setPatient((prevPatient) => ({
      ...prevPatient,
      workingDays: workingDays
    }));
    setOpenEditWorkingDaysDialog(false);
  };

  const renderPatientTabContent = [
    <><br/>
      <EditPatientInfo 
        patientId={patientId}
        patient={patient}
        setPatient={setPatient}
        changePassword={changePassword}
        setChangePassword={setChangePassword}
        handleChange={handleChange}
        handleChangeSelector={handleChangeSelector}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        userProducts={userProducts}
        setUserProducts={setUserProducts}
      />
    </>,
    <><br/>
      <EditPatientPhysiology 
        userId={patientId}
        patient={patient}
        setPatient={setPatient}
        handleChange={handleChange}
        handleChangeSelector={handleChangeSelector}
      />
    </>,
    <><br />
      <Editor
          disabled={false}
          body={patient?.visitsContext}
          setBody={(new_body) => {
              handleChangeEditor("visitsContext", new_body);
          }}
          className="max-height"
          placeholder={"Patient context..."}
          name="Context of the patient"
      />
      <br />
      <EditPatientVisits 
        patientId={patientId}
        visits={visits}
        setVisits={setVisits}
      />
    </>,
    <div><br />SCHEDULE CONTENT</div>,
    <div><br />
      <EditPatientStructures
        patientId={patientId}
        userStructures={userStructures}
        setUserStructures={setUserStructures}
      />
    </div>,
    <div><br />
      <EditPatientDiets 
        userId={patientId}
        setUserCompetitions={setUserCompetitions}
        userCompetitions={userCompetitions}
        setChanges={setChanges}
        changes={changes}
      />
    </div>,
    <div><br />
      <EditPatientMeasures 
        patientId={patientId}
        patient={patient}
      />
    </div>,
    <div><br />
      <EditPatientsCompetitions 
        userId={patientId}
        setUserCompetitions={setUserCompetitions}
        userCompetitions={userCompetitions}
        setChanges={setChanges}
        changes={changes}
      />
    </div>,
    <div><br />
    <EditPatientDocuments 
    userId={patientId}
    />
    </div>,
  ]

  async function saveCompetition() {
    let saveAble = true;
    const res = await updateUserCompetitions(userCompetitions._id, userCompetitions).catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not update user competitions.",
        });
        saveAble = false;
      });

    if (saveAble) alertSuccess({ title: 'Saved!', customMessage: 'Patient successfully updated.' })

    return saveAble;
  }

  async function sendMail() {
    const token = patientToken;
    sendVerifyUserMail(patientId, token)
      .then((res) => {
        alertSuccess({
          title: "Success!",
          customMessage: "Verification mail sended."
        })
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not send verification mail to user"
        })
      })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title={patient?._id?'Patient: ' + (patient.name?patient.name:'') + ' ' + (patient.surname?patient.surname:''):'New patient'}>
          <div className='mt-5'>
            {/* {patientId && (
              <Button
                onClick={() => {
                  // console.log(changes)
                  sendMail();
                }}
                variant="outlined"
                color="secondary"
                style={{ marginRight: '20px' }}>
                Send Mail Verification
              </Button>
            )} */}
            <Button
              onClick={() => {
                // console.log(changes)
                if (changes > 0) setOpenConfirmDialog(1);
                else history.push("/patients");
              }}
              variant="outlined"
              style={{ marginRight: '20px' }}>
              Back
            </Button>
            <Button
              onClick={() => {
                  savePatient(true)
                }}
              variant="outlined"
              color="primary"
              style={{ marginRight: '20px' }}>
              Save patient
            </Button>
            <ConfirmDialog
                title={
                  "Are you sure you want to go back? You will lose all your changes"
                }
                open={openConfirmDialog === 1}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  history.push("/patients")
                }}
                onDecline={() => {
                  setChanges(0);
                }}
              />
            {patientId && <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(2)}
                  variant="outlined"
                  color="secondary">
                  Delete patient
                </Button>
              </MuiThemeProvider>
              <ConfirmDialog
                title={'Are you sure you want to delete this patient?'}
                open={openConfirmDialog === 2}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteUser(patientId).then(res => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({ title: 'Deleted!', customMessage: 'Patient deleted successfully' })
                      history.push('/patients')
                    }
                  }).catch(error => {
                    alertError({ error: error, customMessage: 'Could not delete patient.' })
                  })
                }}
              />
            </>}
          </div>
        </CardHeader>
        <CardBody>
						<PatientTabBlock
              onClick={async (newValue) => {
                console.log(tabValue, newValue, changes)
                if (changes > 0) {
                  if (tabValue === 0){
                    let res = await savePatient(false);
                    if (!res) return false;
                  } else if (tabValue === 1) {
                    let res = await savePatientColumns(false);
                    if (!res) return false;
                    alertSuccess({title: "Saved!", customMessage: "Patient updated succesfully"})
                  } else if (tabValue === 2) {
                    let res = await savePatientColumns(false);
                    if (!res) return false;
                    alertSuccess({title: "Saved!", customMessage: "Patient updated succesfully"})
                  } else if (tabValue === 3) {
                    
                  } else if (tabValue === 4) {

                  } else if (tabValue === 5) {

                  } else if (tabValue === 6) {

                  } else if (tabValue === 7) {
                    const res = await saveCompetition();
                    if (!res) return false;
                    
                  } else if (tabValue === 8) { //Competitions
                    
                  }
                }
                setChanges(0);
                setTabValue(newValue)
                return true;
              }}
							patientTabContent={
								renderPatientTabContent
							}
						/>
        </CardBody>
          <EditWorkingDaysDialog 
            open={openEditWorkingDaysDialog}
            setOpen={setOpenEditWorkingDaysDialog}
            data={selectedWorkingDays}
            onSave={handleSaveWorkingDays}
          />
      </Card>
    </>
  );
}
