import { API, authClient } from "../index";

export const getUserIsakTests = () => {
	return authClient().get(`${API}/user-isak-tests?getDisabled=true`);
};

export const getUserIsakTestsById = (id) => {
	return authClient().get(`${API}/user-isak-tests/${id}`);
};

export const getUserIsakTestsByUserId = (userId) => {
	return authClient().get(`${API}/user-isak-tests/user/${userId}`);
};

export const deleteUserIsakTests = (id) => {
	return authClient().delete(`${API}/user-isak-tests/${id}`);
};

export const postUserIsakTests = async (userIsakTests) => {
	return authClient().post(`${API}/user-isak-tests`, userIsakTests);
};

export const updateUserIsakTests = async (id, userIsakTests) => {
	return authClient().put(`${API}/user-isak-tests/${id}`, userIsakTests);
};

export const changeStatusUserIsakTests = async (id, active) => {
	return authClient().put(`${API}/user-isak-tests/change-status/${id}`, {
		active,
	});
};