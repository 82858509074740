import React, { useEffect, useState } from 'react';
import Table from "../app/components/tables/table";
import { foodCalcs, getDataBlocks } from './structuresCalcs';
import { getFoodById } from '../api/food';
import { alertError } from './logger';

export function calcCompetitionData(competition) {
    let positiveUnevenness = 0;
    let negativeUnevenness = 0;
    let totalKilometers = 0;

    const trams = competition?.trams || [];
    for (let i = 0; i < trams.length; ++i) {
        const actT = trams[i];
        positiveUnevenness += parseFloat(actT?.positiveUnevenness) || 0;
        negativeUnevenness += parseFloat(actT?.negativeUnevenness) || 0;
        totalKilometers += parseFloat(actT?.longitude) || 0;
    }

    return {
        ...competition,
        positiveUnevenness: positiveUnevenness,
        negativeUnevenness: negativeUnevenness,
        totalKilometers: totalKilometers
    }
}

function sortByNumber(a, b) {
    if (a.number < b.number) return -1;
    if (a.number > b.number) return 1;
    return 0;
}

export const tramsTable = [
    {
        dataField: "name",
        text: ""
    },
    {
        dataField: "longitude",
        text: "KM",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "positiveUnevenness",
        text: "D+",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "negativeUnevenness",
        text: "D-",
        headerAlign: "center",
        align: "center"
    },
    {
        dataField: "time",
        text: "Tiempo",
        headerAlign: "center",
        align: "center"
    },
    {}
]

export function getDataTrams(trams) {

    let data = [];

    for (let i = 0; i < trams?.length; ++i) {
        let elem = {};
        const actT = trams[i];

        elem.number = actT?.number;
        elem.name = actT?.name;
        elem.longitude = actT?.longitude;
        elem.positiveUnevenness = actT?.positiveUnevenness;
        elem.negativeUnevenness = actT?.negativeUnevenness;
        elem.time = actT?.time || "0:00";

        data.push(elem);
    }
    
    data.sort(sortByNumber)
    return data;
}

export const BlocksTable = React.memo((props) => {
    const { blocks, setNt } = props;

    const [dataBlocks, setDataBlocks] = useState([]);

    useEffect(() => {
        if (blocks) getDataB(blocks);
    }, [blocks])

    const blockColumns = [
        {
            dataField: "number",
            text: "type",
            formatter: typeFormatter,
        },
        {
            dataField: "kcals",
            text: "kcals",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "carbohydrates",
            text: "carbohydrates",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "proteins",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fiber",
            text: "fiber",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fats",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        }
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}
    
    function typeFormatter(cell) {
        const block = blocks.find((x) => cell === x.number);
        return (block?.isIntake? block?.intake.isSnack? "Snack" : "Main" : "Exercise");
    }

    async function getDataB(blocks) {
        const dataBlocks = await getDataBlocks(blocks);
		setDataBlocks(dataBlocks.data);
        delete dataBlocks.data;
        setNt(dataBlocks);
    }

    return (<Table data={dataBlocks} columns={blockColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 4
export const IntakesCompetitionTable = React.memo((props) => {
    const { tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const foodColumns = [
        {
            dataField: "name",
            text: ""
        },
        {
            dataField: "grams",
            text: "grams"
        },
        {
            dataField: "kcals",
            text: "kcals"
        },
        {
            dataField: "carbohydrates",
            text: "carbohydrates",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "proteins",
            text: "proteins",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fiber",
            text: "fiber",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        },
        {
            dataField: "fats",
            text: "fats",
            formatter: numbersFormatter,
            align: 'center',
            headerAlign: 'center',
        }
    ];
    
    function numbersFormatter(cell) {return cell.toFixed(1);}

    async function getDataI(tramsXuser) {
        const foodIds = [...new Set(tramsXuser.flatMap(item => item.foods.map(food => food.food)))];
        
        let data = [];

        for (let i = 0; i < foodIds?.length; ++i) {
            let elem = {};
            const id = foodIds[i];

            const res = await getFoodById(id)
                .catch((error) => {
                    alertError({
                        error: error,
                        customMessage: "Could not obtain food"
                    })
                })

            const food = res.data;

            elem.name = food.fullName.es;
            elem.kcals = parseFloat(food?.kcals);
            elem.carbohydrates = parseFloat(food?.carbohydrates);
            elem.proteins = parseFloat(food?.proteins);
            elem.fats = parseFloat(food?.fats);
            elem.fiber = parseFloat(food?.fiber);
            elem.grams = 100;

            data.push(elem);
        }

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={foodColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 6
export const NutritionalBag = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "quantity",
            text: "quantity",
        },
        {
            dataField: "name",
            text: "name",
            align: 'center',
            headerAlign: "center"
        },
        {
            dataField: "type",
            text: "product",
            align: 'center',
            headerAlign: "center",
            formatter: typeFormatter
        },
    ]
    
    function typeFormatter(cell, row) {
        return cell;
    }

    async function getDataI(tramsXuser) {

        async function getFood(food) {
            let elem = {};
            await getFoodById(food.food)
                .then((res) => {
                    elem = res.data;
                })
                .catch((error) => {
                    console.log(error)
                })
            return elem;
        }

        const uniqueFoodsMap = new Map();

        for (const item of tramsXuser) {
            for (const food of item.foods) {
                if (!uniqueFoodsMap.has(food.food)) {
                    const definedFood = await getFood(food);
                    uniqueFoodsMap.set(food.food, { ...food, definedFood });
                } else {
                    const existingFood = uniqueFoodsMap.get(food.food);
                    existingFood.quantity += food.quantity || 0;
                    existingFood.grams += food.grams || 0;
        
                    uniqueFoodsMap.set(food.food, existingFood);
                }
            } 
        }

        let data = [];

        uniqueFoodsMap.forEach((food) => {
            let elem = {};
            
            elem._id = food.food;
            if (food?.selectedPortion >= 0) {
                const defF = food.definedFood;
                const portion = defF?.portions?.find((x) => x._id === food.selectedPortion);
                elem.quantity = `${food.quantity} ${portion.name}`;
            } else elem.quantity = `${food.grams} grams`;
            elem.name = food.name;
            elem.type = food.name;

            data.push(elem);
        })

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})

//Punto 7
export const DistributionIntakesTable = React.memo((props) => {
    const { trams, tramsXuser } = props;

    const [dataIntakes, setDataIntakes] = useState([]);

    useEffect(() => {
        if (tramsXuser) getDataI(tramsXuser);
    }, [tramsXuser])

    const tramsColumns = [
        {
            dataField: "name",
            text: "title"
        },
        {
            dataField: "foods",
            text: "quantity",
            align: 'center',
            headerAlign: "center",
            formatter: quantityFormatter
        },
        {
            dataField: "foods",
            text: "food",
            align: 'center',
            headerAlign: "center",
            formatter: foodsFormatter
        },
        {
            dataField: "foods",
            text: "observations",
            align: 'center',
            headerAlign: "center",
            formatter: observationsFormatter
        },
    ]

    function quantityFormatter(cell, row) {

        function getFoodInfoText(food, definedFoods) {
            if (food?.selectedPortion >= 0) {
                const actDF = definedFoods?.find((x) => x._id === cell[0].food)
                const quantityName = actDF?.portions?.find((x) => x._id === cell[0].selectedPortion)
                return cell[0].quantity + " " + quantityName.name;
            } else return cell[0].grams + " grams";
        }
        let text = "";
        const definedFoods = row?.definedFoods;
        //console.log(cell, row, definedFoods)

        if (cell?.length > 0) {
            text += getFoodInfoText(cell[0], definedFoods);
            for (let i = 1; i < cell?.length; ++i) text += "<br>" + getFoodInfoText(cell[i], definedFoods);
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function foodsFormatter(cell) {
        let text = "no intake";

        if (cell?.length > 0) {
            text = cell[0].name;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.name;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    function observationsFormatter(cell) {
        let text = "";

        if (cell?.length > 0) {
            text = cell[0].observations;
            for (let i = 1; i < cell?.length; ++i) {
                const actF = cell[i];
                text += "<br>" + actF.observations;
            }
        }
    
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    }

    async function getFoods(foods) {
        let data = [];
        for (let i = 0; i < foods?.length; ++i) {
            await getFoodById(foods[i].food)
                .then((res) => {
                    data.push(res.data);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        return data;
    }

    async function getDataI(tramsXuser) {
        let data = [];

        for (let i = 0; i < trams?.length; ++i) {
            let elem = {};
            const act = trams[i];
            const definedTram = tramsXuser.find((x) => parseInt(x.numTram) === act.number)
            const definedFoods = await getFoods(definedTram?.foods);
            
            elem.name = act.name;
            elem.number = act.number;
            elem.foods = definedTram?.foods || [];
            elem.definedFoods = definedFoods || [];

            data.push(elem);
        }

        data.sort(sortByNumber)

		setDataIntakes(data);
    }
    // return (<></>)
    return (<Table data={dataIntakes} columns={tramsColumns} hideSearchTable={true} hidePagination={true}/>)
}, (prevProps, nextProps) => {
    return prevProps === nextProps;
})